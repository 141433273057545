import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class Footer extends Component {
	static displayName = Footer.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
        <footer>
            <br/>
            <hr size="2" />
            <p align="center">
                <i>Funded by <a href="http://www.fightingblindness.org/" target="_blank">The Foundation Fighting Blindness</a> </i>
            </p>
            <p align="center">&copy;1996-2024,
                <a href="https://sph.uth.edu/faculty/#SziA6j0ntUkMDapVv0uU99HVaTnCQXXhIURCRplf4tE=" target="_blank"> Lori S. Sullivan, PhD</a> &
                <a href="mailto: stephen.p.daiger@uth.tmc.edu?"> Stephen P. Daiger, PhD</a>
            </p>
            {(window.location.pathname === "/portal/disease/edit" ||
                window.location.pathname === "/portal/disease/new" ||
                window.location.pathname === "/portal/references/edit" ||
                window.location.pathname === "/portal/references/new") ?
                <a className="jodit-status-bar-link-small pad-10p" target="_blank" href="https://xdsoft.net/jodit/">
                    POWERED BY JODIT
                </a>
                : null}
      </footer>
    );
  }
}
