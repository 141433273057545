import { Home } from "./components/Home";
import { Disease } from "./components/Disease";
import { Summaries } from "./components/Summaries";
import { Symbols } from "./components/Symbols";
import { WhatsNew } from "./components/WhatsNew";
import { References } from "./components/References";
import { Portal } from "./portal/Portal";
import { PortalDisease } from "./portal/Disease";
import { DiseasesForm } from "./portal/DiseasesForm";
import { Pagenotfound } from "./components/Pagenotfound";
import { Register } from "./portal/Register";
import { PortalReferences } from "./portal/References";
import { ReferencesForm } from "./portal/ReferencesForm";
import { MapCloneDates } from "./portal/MapCloneDates";
import { MapCloneDatesForm } from "./portal/MapCloneDatesForm";
import { ChangePassword } from "./portal/ChangePassword";
import { ContactUs } from "./components/ContactUs";
import { ResetMyPassword } from "./portal/ResetMyPassword";
import { FAQ } from "./components/FAQ";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/disease',
        element: <Disease />
    },
    {
        path: '/summaries',
        element: <Summaries />
    },
    {
        path: '/faq',
        element: <FAQ />
    },
    {
        path: '/symbols',
        element: <Symbols />
    },
    {
        path: '/whatsnew',
        element: <WhatsNew />
    },
    {
        path: '/references',
        element: <References />
    },
    {
        path: '/contactus',
        element: <ContactUs />
    },
    {
        path: '/portal',
        element: <Portal />
    },
    {
        path: '/portal/disease',
        element: <PortalDisease />
    },
    {
        path: '/portal/users/new',
        element: <Register />
    },
    {
        path: '/portal/users/changepassword',
        element: <ChangePassword />
    },
    {
        path: '/portal/forgotpassword',
        element: <ResetMyPassword />
    },
    {
        path: '/portal/emailverified',
        element: <ResetMyPassword />
    },
    {
        path: '/portal/resetpassword',
        element: <ResetMyPassword />
    },
    {
        path: '/portal/disease/new',
        element: <DiseasesForm />
    },
    {
        path: '/portal/disease/edit',
        element: <DiseasesForm />
    },
    {
        path: '/portal/references',
        element: <PortalReferences />
    },
    {
        path: '/portal/references/new',
        element: <ReferencesForm />
    },
    {
        path: '/portal/references/edit',
        element: <ReferencesForm />
    },
    {
        path: '/portal/mapclonedates',
        element: <MapCloneDates />
    },
    {
        path: '/portal/mapclonedates/new',
        element: <MapCloneDatesForm />
    },

    {
        path: '/portal/mapclonedates/edit',
        element: <MapCloneDatesForm />
    },
    {
        path: '*',
        element: <Pagenotfound />
    },
    {
        path: '*.htm',
        element: <Pagenotfound />
    }
];

export default AppRoutes;
