import React, { Component } from 'react';

export class FormHeader extends Component {
	static displayName = FormHeader.name;
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {

	
		const userInfo = sessionStorage.getItem("user");
		if (userInfo === undefined || userInfo === "") {
			window.location = "/portal";

		}
		else{
			return (
				<div className="logo">
					<a className="pad-1" href="" target="_blank">
						<img src="/img/logo.gif" alt="RetNet:" width="250" border="0" />
					</a>
					<hr />
				</div >
			);
		} 
	}
}