import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FormHeader } from "./FormHeader";
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { Input } from "./components/Input";
import { Modal } from './components/Modal';
import $ from 'jquery'

export class ResetMyPassword extends Component {
    static displayName = ResetMyPassword.name;

    constructor(props) {
        super(props);

        ResetMyPassword.sendResetCode = ResetMyPassword.sendResetCode.bind(this);
        ResetMyPassword.submitNewPassword = ResetMyPassword.submitNewPassword.bind(this);
    }



    async registerHandler(e) {
        e.preventDefault();
        const form_ = e.target, submitter = document.querySelector("input.login");

        const formData = new FormData(form_, submitter), dataToSend = {};
   
        for (const [key, value] of formData) {
            dataToSend[key] = value;
        }

        try {
            const response = await fetch("api/authentication/resetpassword", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(dataToSend),
                headers: {
                    "content-type": "Application/json",
                    "Accept": "application/json"
                }
            });
            const data = await response.json();
            if (response.ok) {
                e.target.reset();
                Modal.modalDisplay(response.ok, data.message);
            }
            else if (data.message)
            {
                Modal.modalDisplay(false, data.message);
            }
            else {

                if (!data.succeeded) {
                    Modal.modalDisplay(false, data.errors[0].description);
                    //messageEl.innerHTML = data.errors[0].description;
                } else {
                    Modal.modalDisplay(false, "Something went wrong, please try again.");
                    //messageEl.innerHTML = "Something went wrong, please try again.";
                }
            }
        }
        catch (err) {
            window.location.href = "/portal";
        }
    }

    static async sendResetCode(e) {
       
        try {

            var email = $("#emailAddress").val();

            const response = await fetch("api/authentication/sendresetpasswordtoken?email=" + email, {
                
                credentials: "include",
                headers: {
                    "content-type": "Application/json",
                    "Accept": "application/json"
                }
            });

            const data = await response.json();
            if (response.ok) {
                Modal.modalDisplay(response.ok, data.message);
            }
            else if (data.message) {
                Modal.modalDisplay(false, data.message);
            }
            else {

                if (!data.succeeded) {
                    Modal.modalDisplay(false, data.errors[0].description);
                    //messageEl.innerHTML = data.errors[0].description;
                } else {
                    Modal.modalDisplay(false, "Something went wrong, please try again.");
                    //messageEl.innerHTML = "Something went wrong, please try again.";
                }
            }
            
        }
        catch (err) {
            window.location.href = "/portal";
        }
    }
   
    static async submitNewPassword(e) {

        try {
            const queryString = window.location.search,
                urlParams = new URLSearchParams(queryString),
                token = urlParams.get('token'),
                email = urlParams.get('email');

            var resetpassword = {
                Token: token,
                EmailAddress: email,
                NewPassword: $("#password").val(),
                ConfirmPassword: $("#confirmpassword").val()
            }

            const response = await fetch("api/authentication/resetpassword", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(resetpassword),
                
                headers: {
                    "content-type": "Application/json",
                    "Accept": "application/json"
                }
            });

            const data = await response.json();
            if (response.ok) {
                Modal.modalDisplay(response.ok, data.message);
            }
            else if (data.message) {
                Modal.modalDisplay(false, data.message);
            }
            else {

                if (!data.succeeded) {
                    Modal.modalDisplay(false, data.errors[0].description);
                    //messageEl.innerHTML = data.errors[0].description;
                } else {
                    Modal.modalDisplay(false, "Something went wrong, please try again.");
                    //messageEl.innerHTML = "Something went wrong, please try again.";
                }
            }

        }
        catch (err) {
            window.location.href = "/portal";
        }
    }

    static renderForm() {
        if (document.location.pathname === "/portal/forgotpassword") {
            return (
                <div>
                     <Helmet>
                        <title>RetNet Poral: Forgot Password</title>
                    </Helmet>
                    <h3 className="pad-10p">Forgot Password</h3>
                    <hr />
                    <form action="#" className='register'>
                        <Input label="Please provide your email address" name="emailAddress" type="email" required />
                        <br />
                        <div className="pad-1 f-inline-end">
                            <a type="submit" className='btn btn-primary margin-5' alt="Send Code"
                                onClick={e => { e.preventDefault(); ResetMyPassword.sendResetCode(); }}>
                                Send Code</a>
                            <a className='register btn btn-secondary margin-5' href="/portal/">Cancel</a>
                        </div>
                    </form>
                </div>
            );
        }
        else if (document.location.pathname === "/portal/resetpassword") {
            const queryString = window.location.search,
                urlParams = new URLSearchParams(queryString),
                token = urlParams.get('token'),
                email = urlParams.get('email');

            if (token && email) {

                return (<div>
                    <Helmet>
                        <title>RetNet Poral: Reset Password</title>
                    </Helmet>
                    <h3 className="pad-10p">Reset Password</h3>
                    <hr />
                        <form action="#" className='register'>
                            <Input label="Password" name="password" type="password" required />
                            <Input label="Confirm Password" name="confirmpassword" type="password" required />
                            <br />
                            <div className="pad-1 f-inline-end">
                                <a type="submit" className='btn btn-primary margin-5' alt="Send Code"
                                    onClick={e => { e.preventDefault(); ResetMyPassword.submitNewPassword(); }}>
                                    Change Password</a>
                                <a className='register btn btn-secondary margin-5' href="/portal/">Cancel</a>
                            </div>
                        </form>
                    </div>
                );
            }
            else {
                document.location = "/portal";
            }
        }


        else if (document.location.pathname === "/portal/emailverified") {
            return (
                <div>
                    <Helmet>
                        <title>RetNet Poral: Email Verification</title>
                    </Helmet>
                    <h3 className="pad-10p">Email Verified</h3>
                    <hr />
                    <h5><i id="icon-success" className="bi bi-check2-circle f-left text-green"></i>&nbsp; Email successfully verified!</h5>
                    <br />
                    <div className="pad-1 f-inline-end">
                        <a className="btn btn-primary" href="/portal">Go to Login</a>
                    </div>
                </div>
            );
        }

    }



    render() {
        var content = ResetMyPassword.renderForm();



        return (

            <div className="form-container">

                <FormHeader />
                <Modal title="Forgot Password"
                    okurl="/portal"
                    failurl="/portal" />

                <div className="element-center w-40 pad-1 box-shadow bg-white-smoke border-gray border-round">
                   
                    {
                        content
                    }
                    <p>&nbsp;</p>
                    <br />
                </div>



            </div>
        );
    }

}