import React, { Component, useRef, Button } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { ModalYesNo } from "./components/ModalYesNo";
import { Modal } from "./components/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export class PortalReferences extends Component {
	static displayName = PortalReferences.name;
	constructor(props) {
		super(props);
		this.state = {
			references: [],
			loading: true
		};
		//this.handleChange = this.handleChange.bind(this);
	}

	async callbackYesDelete(childData, callbackendpoint){

		const submitendpoint = "api/references/" + callbackendpoint

		const response = await fetch(submitendpoint, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(childData),
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();
		ModalYesNo.modalDisplay(false);
		Modal.modalDisplay(response.ok, data.message, "portal/references");
		
	}

	delete = (val) => {
		this.setState({
			isDelete: val
		})
	}

	formMode = (val) => {
		this.setState({
			isInsert: val
		})
	}

	componentDidMount() {
		this.populateDiseasesData();
	}


	handleChange() {
		this.setState({ chromNo: 2 });
	};

	static ConfirmDelete(show, question, id, endpoint, color) {
		ModalYesNo.modalDisplay(show, question, id, endpoint, color);
	}


	static selectChromosome(id) {
		const li = document.getElementById("tab:"+id);
	}


	static renderReferences(references) {

		return (
			<div>

				<Tabs>
					{


						<TabList>
							{
								references.map(letterGroup => {
									return (
										<Tab key={letterGroup.letterGroup} id={letterGroup.letterGroup}>{letterGroup.letterGroup}</Tab>
									);
								})
							}
						</TabList>
					}
				


				{
					references.map(letters => {
						return (
							<TabPanel key={letters.letterGroup}>


									<div key={letters.letterGroup} id={letters.letterGroup}>
										<h2 name={letters.letterGroup}>
											{letters.letterGroup}
										</h2>
									<table className="table table-bordered" aria-labelledby="tableLabel">
										<thead>
											<tr align="center" key={letters.letterGroup}>
												<th className="bg-blue text-whitern pad-2p w-5p"></th>
												<th className="w-15 bg-blue text-whitern">ID</th>
												<th className="bg-blue text-whitern">Author(s)</th>
												<th className="bg-blue text-whitern">Title</th>
												<th className="w-10 bg-blue text-whitern">Action</th>
											</tr>
										</thead>

										<tbody>
											{
												letters.referenceItems.map(reference => {

													return (
														<tr key={reference.reference} id={reference.link}>
															{
																(reference.status === 1) ?
																	<td className="bg-yellow pad-2p" title="Draft"></td> :
																	(reference.status === 2) ?
																		<td className="bg-green pad-2p" title="Published"></td> :
																		<td className="bg-red pad-2p" title="Archived"></td>
															}
															<td title={reference.id}>
																{reference.referenceName}
															</td>
															<td>
																<span dangerouslySetInnerHTML={{
																	__html:
																		reference.authors
																}} />
															</td>
															<td>
																<span dangerouslySetInnerHTML={{
																	__html:
																		reference.title + ". " 
																}} />

																<span dangerouslySetInnerHTML={{
																	__html:
																		(reference.issuePages) ?
																			reference.issuePages + ". " : ""
																}} />
																{
																	(reference.year) ? <span> ({reference.year}). </span> : ""
																}
																{
																	(reference.medLine) ?
																		<a href={"http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?db=PubMed&amp;cmd=Retrieve&amp;list_uids=" + reference.medLine + "&amp;dopt=Abstract"} target="_blank">[PubMed]</a>
																		: ""
																}
															</td>

														<td className="center">
																<a href={"/portal/references/edit?id=" + reference.id} title="Edit"> <i className="bi bi-pencil-square"></i> </a> &nbsp;
																{(reference.status !== 10) ?
																	<a onClick={() => this.ConfirmDelete(true, "Are you sure you want to Archive <strong>" + reference.referenceName + "</strong>?", reference.id, "delete", "orange")}><i className="bi bi-archive text-orange" title="Delete" ></i> </a>
																	:
																	<a onClick={() => this.ConfirmDelete(true, "Are you sure you want to Permanently Delete Reference with Sort By <strong>" + reference.referenceName + "</strong>?", reference.id, "permanentlydelete", "red")}><i className="bi bi-trash text-red" title="Permanently Delete"></i> </a>
																}
																
														</td>
														</tr>
													);
												})
											}
										</tbody>
										</table>
								</div>
							</TabPanel>
					)
				})
					}
				</Tabs>
			</div>
		);
	}

	render() {
		let contents = this.state.loading
			? <div className="loader section-center"></div>
			: PortalReferences.renderReferences(this.state.references, this.state.chromNo)
		
		return (
			<div className="form-container">

				<Helmet>
					<title>RetNet Portal: References </title>
				</Helmet>

			  <FormHeader />
				<FormNav />
				<ModalYesNo title="References" parentCallback={this.callbackYesDelete} />
				<Modal title="References"
					okurl="/portal/references"
					failurl="/portal/references" />

				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					
					<h3 className="pad-10p">References <a type="button" href="/portal/references/new" title="Add New Record"> <i className="bi bi-plus-circle"></i></a ></h3> 
					
					<hr />
					
					
					<div>
					
						{contents}

					 </div>
			  </div>
		  </div>
		);
		
	}

	async populateDiseasesData() {
		const response = await fetch('/api/references', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ references: data, loading: false });
		}
		else {
			alert(data.message);
		}
	}

}

