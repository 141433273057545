import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FormHeader } from "./FormHeader";
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { Input } from "./components/Input";
import { Modal } from './components/Modal';
export class Register extends Component {
    static displayName = Register.name;

    async registerHandler(e) {
        e.preventDefault();
        const form_ = e.target, submitter = document.querySelector("input.login");

        const formData = new FormData(form_, submitter), dataToSend = {};
        const messageEl = document.querySelector(".loginMessage");
        //const userName = document.querySelector(".loginMessage");
        //const email = document.querySelector(".loginMessage");
        //const password = document.querySelector(".loginMessage");

        for (const [key, value] of formData) {
            dataToSend[key] = value;
        }


        const newUserName = dataToSend.Name.trim().split(" ");
        dataToSend.UserName = newUserName.join("");
        try {
            const response = await fetch("api/authentication/register", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(dataToSend),
                headers: {
                    "content-type": "Application/json",
                    "Accept": "application/json"
                }
            });
            const data = await response.json();
            if (response.ok) {
                e.target.reset();
                Modal.modalDisplay(response.ok, data.message);
            }
            else {

                if (!data.succeeded) {
                    Modal.modalDisplay(false, data.errors[0].description);
                    //messageEl.innerHTML = data.errors[0].description;
                } else {
                    Modal.modalDisplay(false, "Something went wrong, please try again.");
                    //messageEl.innerHTML = "Something went wrong, please try again.";
                }
            }
        }
        catch (err) {
            window.location.href = "/portal";
        }
    }


    render() {
        return (

            <div className="form-container">
                <Helmet>
                    <title>RetNet Poral: Register New User</title>
                </Helmet>
                <FormHeader />
                <FormNav />

                <Modal title="RetNet Poral: Register New User"
                    okurl="/portal"
                    failurl="/portal" />

                <div className="form-content box-shadow bg-white-smoke border-gray border-round">
                    <h3 className="pad-10p">Register New User</h3>
                    <hr/>
                    <form action="#" className='register' onSubmit={this.registerHandler}>

                        <Input label="Name" name="Name" type="Text" cssclass="w-25" />
                        <Input label="Email" name="Email" type="email" cssclass="w-25" />
                        <Input label="Password" name="PasswordHash" type="password" cssclass="w-25" />


                        <br />
                        <div className="pad-1 f-inline-end">
                            <input type="submit" className='btn btn-primary margin-5' value="Register" />
                            {/*<input type="submit" className='btn-red register btn btn-secondary' value="Cancel" onClick={() => this.props.handler(false)} />*/}
                            <a className='register btn btn-secondary margin-5' href="/portal/">Cancel</a>

                        </div>
                    </form>
                    <p>&nbsp;</p>
                    <br />
                </div>



            </div>
        );
    }

}