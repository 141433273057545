import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Login } from "./Login"
import CanvasJSReact from '@canvasjs/react-charts';
import { Register } from "./Register"
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";

export class Portal extends Component {
	static displayName = Portal.name;
	constructor(props) {
		super(props);
		this.state = {
			userInfo: false,
			loading: false,
			summaryProgress: [],
			progressLoading: true
		};
	}

	componentDidMount() {
		//this.userInfo = sessionStorage.getItem("user");

	}

	componentWillMount() {
		this.checkAuth();
		this.getmappingprogress();
	}


	handler = (val) => {
		this.setState({
			showRegister: val
		})
	}


	closeRegisterUser() {
		this.setState({
			showRegister: false
		});
	}

	static handleClick() {
		
	}

	renderPortal() {

		let progress = this.state.progressLoading
			? ''
			: this.renderChart(this.state.summaryProgress);

		let content = null;

		var info = this.state.userInfo;

			content =
				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					<Helmet>
						<title>RetNet Portal</title>
					</Helmet>

					<h3 className="center">Welcome to RetNet Portal</h3>
					<hr/>
					{
						progress
					}
			</div>

		if (info) {

			return (

				<div className="form-container">
					<FormNav />
					{content}
				</div>
			)
		}
		else if (this.state.loading || this.state.progressLoading) {
			return "";
		}
		else { 
			return (
				<Login />
			)
		}

	}
	
	renderChart(chartData) {


		var mapDataPoints = [],
			identifiedDataPoints = [];

		chartData.forEach(function (p) {
			mapDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.mapped });
			identifiedDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.identified });
		});

		var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		var options = {
			animationEnabled: true,
			theme: "light1",
			zoomEnabled: true,
			zoomType: "x",
			title: {
				text: "Mapped and Identified Retinal Disease Genes",
				fontSize: 20,
				fontFamily:  "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
			},
			axisY: {
				title: "",
				valueFormatString: "#0",
				includeZero: true,
				suffix: "",
				prefix: ""
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toogleDataSeries,
				fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
			},
			toolTip: {
				shared: true
			},
			data: [{
				type: "area",
				//fillOpacity: 1,
				name: "Mapped",
				markerSize: 5,
				showInLegend: true,
				xValueFormatString: "MMMM-YYYY",
				yValueFormatString: "",
				dataPoints: mapDataPoints
			}, {
				type: "area",
				//fillOpacity: 1,
				//color: "red",
				name: "Identified",
				markerSize: 5,
				showInLegend: true,
				yValueFormatString: "",
				dataPoints: identifiedDataPoints
			}]
		};

		return (

			<div>
				
				<CanvasJSChart
					options={options}
					onRef={(ref) => (this.chart = ref)}/>
				<br/>
				<div className="f-inline-end margin-r-1">
					<a className="align-right" onClick={() => {
						this.chart.exportChart({ format: "jpg" });
					}} alt="Download">
						<i className="bi bi-download"></i> Download
					</a>
				</div>
				<span className="text-small text-italic">** Please ensure that your browser is in 100% zoom for better quality.</span><br />
				<span className="text-small text-italic">** Highlight an area to zoom in.</span>
			</div>
		)
	}


	render() {
		
		let welcomeContent = this.state.loading ? "" :
			this.renderPortal();

		return (
			<div>
				<Helmet>
					<title>RetNet: Portal</title>
				</Helmet>
				<FormHeader/>
				{
					welcomeContent
				}
			</div>
		);
	}


	async checkAuth() {

		const response = await fetch('/api/authentication', {
			signal: AbortSignal.timeout(20000)
		});

		const data = await response.json();

		if (response.ok) {
			this.setState({ userInfo: true, loading: false });
		}
		else {
			this.setState({ userInfo: false, loading: false });
		}

	}

	async getmappingprogress() {
		const response = await fetch('/api/summary/getmappingprogress', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryProgress: data, progressLoading: false });
		}
		else {
			alert(data.message);
		}
	}
}
