import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { Input } from "./components/Input";
import { Modal } from "./components/Modal";
import { DropDown } from "./components/DropDown";
import { MapCloneDates } from './MapCloneDates';

export class MapCloneDatesForm extends Component {
	static displayName = MapCloneDatesForm.name;
	constructor(props) {
		super(props);
		this.state = {
			mapcloneitem: {},
			loading: true,
			references: {},
			refloading: true
		};

		MapCloneDatesForm.addRow = MapCloneDatesForm.addRow.bind(this);
		MapCloneDatesForm.submitHandler = MapCloneDatesForm.submitHandler.bind(this);
	}

	static displayMonths(id, cssclass, value) {

		return (
			<DropDown caption="--" name={id} cssclass={cssclass} value={value} isMOnths={true} />
		)
	}


	static displayReferenceType(id, cssclass, value) {
		var arr = [
			{ value: "Map", key: 1 },
			{ value: "Clone", key: 2 },
			{ value: "Other Map", key: 3 },
			{ value: "Other Clone", key: 4 }
			
		];
		return (
			<DropDown options={arr} caption="--" name={id} cssclass={cssclass} value={value} />
		)
	}


	static displayReferences(references, id, cssclass, value, changeHandler) {
		var arr = [];

		if (Array.isArray(references)) {
			references.map(p => {
				return (
					arr.push({ value: p.reference, key: p.referenceId })
				)
			})

			return (
				<DropDown options={arr} caption="--" name={id} cssclass={cssclass} value={value}/>
			)
		}
	}


	componentDidMount() {
		this.populateCloneDatesData();
		this.populateReferences();
	}


	static displayData(cloneDates)
	{
		if (window.location.pathname === "/portal/mapclonedates/edit") {

			for (var key in cloneDates) {
				this.setformValue(cloneDates, key);
			}
		}
	}

	static setData(id, value) {

		const field = document.getElementById(id);
		if (field !== null) {
			field.value = value
		}
	}

	static setformValue(clonedates, field) {
		
		const inputfield = document.getElementById(field);
		if (inputfield !== null) {
			inputfield.value = clonedates[field]
		}
	}

	static closeModal() {
		const modal = document.getElementById("modal");
		modal.style.display = "none";
	}

	static removeRow(id) {
		var element = document.getElementById(id);
		
		element.remove()
	}


	static addRow(references, diseaseid, accessiondisease) {
		
		this.setState({ loading: true });
		references.push({
			accessionDisease: accessiondisease,
			chrom: null,
			chromTag: null,
			chromosome: null,
			chromosomeNo: 1,
			diseaseId: diseaseid,
			month: null,
			recordType: null,
			reference: null,
			referenceId: null,
			rindex: document.getElementById("tbdates").rows.length,
			title: null,
			year: null
		})
		this.setState({ mapcloneitem: references,  loading: false });
	}

	static async submitHandler(e) {
		
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		var tbdates = document.getElementById("tbdates"),
			dId = urlParams.get('id'),
			mapclones = [];

		for (var i = 1; i <= this.state.mapcloneitem.length; i++) {
			
			var row = document.getElementById("rrefType" + i);
			
			if (row !== null ) {
				mapclones.push({
					id: 0,
					accessionDisease: "",
					reference: "",
					title: "",
					year: document.getElementById("rYear" + i).value,
					month: document.getElementById("rmonth" + i).value,
					recordType: document.getElementById("rrefType" + i).value,
					diseaseId: dId,
					referenceId: document.getElementById("ref" + i).value
				})
			}
		}

		if (mapclones.length > 0) {
			const submitendpoint = "/api/mapclonedates"

			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(mapclones),
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);
		}
		else {
			const submitendpoint = "/api/mapclonedates/deleteLines?id=" + dId

			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);
		}
	}

	static renderForm(data, references) {

		return (
			<div>
			
				<div className="btn-group">
					<a href="portal/mapclonedates" className="pad-10p"><i className="bi bi-x-lg"></i></a>
				</div>
				&nbsp;
				<hr />
				<p id="dId"></p>
				<Input label="Id" name="id" type="Text" cssclass="w-25 display-none" required={true} readOnly="readOnly" value={data[0].sortNo} />
		
				<h5>{data[0].chromosome + " : "} <span className="text-bluern font-weight-bold">{ data[0].sortNo}</span></h5>

				<br/>
					<h5>References<a className="btn btn-link" onClick={() => this.addRow(data) }><i className="bi bi-plus-circle text-bluern"></i></a></h5>
				<table className="f-left table table-bordered" id="tbdates">
					<thead>
						<tr>
							<th className="bg-blue text-whitern center">Reference Code</th>
							<th className="bg-blue text-whitern center">Month</th>
							<th className="bg-blue text-whitern center">Year</th>
							<th className="bg-blue text-whitern center">Reference Type</th>
							<th className="bg-blue text-whitern center"></th>
						</tr>
					</thead>
					<tbody>
						{
							data.map(p => {
								return (
									<tr key={ p.rindex } id={"tr"+p.rindex}>
										<td>
											{ MapCloneDatesForm.displayReferences(references, "ref" + p.rindex, "", p.referenceId, p.referenceId)}	
										</td>
										<td>
											<DropDown caption="--" name={"rmonth" + p.rindex} value={p.month} isMonths={true} />
										</td>
										<td>
											<DropDown caption="--" name={"rYear" + p.rindex} value={p.year} isYears={true} />
										
										</td>
										<td>
											{MapCloneDatesForm.displayReferenceType("rrefType" + p.rindex, "", p.recordType) }
										</td>
										<td className="center">
											<button className="btn btn-link" onClick={() => this.removeRow("tr" + p.rindex)}><i className="bi bi-trash text-bluern" title="Delete" ></i> </button>
										</td>
									</tr>
								)
							}
						)}
					</tbody>
				</table>
				<div className="pad-1 f-inline-end">
					<button className="btn btn-primary" onClick={() => this.submitHandler()}> Submit</button>
					<a className='btn-red register btn btn-secondary margin-5' href="/portal/mapclonedates">Cancel</a>
				</div>
			</div>
		)
	}

	render() {
		let contents = this.state.loading
			? ""
			: MapCloneDatesForm.renderForm(this.state.mapcloneitem, this.state.references)
		
		var pageTitle = "Edit Map and Clone Date";

		return (
			<div className="form-container">
				<Helmet>
					<title>RetNet Portal: { pageTitle} </title>
				</Helmet>

				<Modal title={ pageTitle }
					okurl="/portal/mapclonedates"
					failurl="/portal/mapclonedates"/>


				<FormHeader />
				<FormNav />
				
				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					<h3 className="position-absolute pad-10p">{  pageTitle }</h3>
					{
						contents
					}
					<p>&nbsp;</p>
					<br />
				</div>
			</div>
		);


	}

	async populateCloneDatesData() {

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);


		const response = await fetch("api/mapclonedates/getitem?id=" + urlParams.get('id'), {

			credentials: "include",
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();
	
		this.setState({ mapcloneitem: data, loading: false });
	}

	async populateReferences() {

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		const response = await fetch("api/references/getdiseasereference?id=" + urlParams.get('id'), {

			credentials: "include",
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();
		
		this.setState({ references: data, refloading: false });
	}

}

