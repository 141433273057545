import React, { Component, useRef, Button } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { ModalYesNo } from "./components/ModalYesNo";
import { Modal } from "./components/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export class MapCloneDates extends Component {
	static displayName = MapCloneDates.name;
	constructor(props) {
		super(props);
		this.state = {
			references: [],
			loading: true
		};
		//this.handleChange = this.handleChange.bind(this);
	}

	 async callbackYesDelete(childData){
		
		const submitendpoint = "api/mapclonedates/delete"

		const response = await fetch(submitendpoint, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(childData),
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();
		ModalYesNo.modalDisplay(false);
		Modal.modalDisplay(response.ok, data.message);
		
	}

	delete = (val) => {
		this.setState({
			isDelete: val
		})
	}

	formMode = (val) => {
		this.setState({
			isInsert: val
		})
	}

	componentDidMount() {
		this.populateCloneDates();
	}


	handleChange() {
		
		this.setState({ chromNo: 2 });
	};

	static ConfirmDelete(show, question, id) {
		
		ModalYesNo.modalDisplay(show, question, id);
	}


	static selectChromosome(id) {
		const li = document.getElementById("tab:"+id);
	}


	static renderCloneDates(clonedates) {

		return (
			<div>
				<Tabs>
					{
						<TabList>
							{
								clonedates.map(chrom => {
									return (
										<Tab key={chrom.chromTag} id={chrom.chromTag}>{chrom.chrom}</Tab>
									);
								})
							}
						</TabList>
					}
					{
						clonedates.map(chrom => {
							return (
								<TabPanel key={chrom.chromTag}>
									<h4 className="pad-5p">
										{chrom.chromosome}
									</h4>
									<table className="table table-bordered" aria-labelledby="tableLabel">
										<thead>
											<tr align="center">
												<th className="w-10 bg-blue text-whitern">Sort Number</th>
												<th className="bg-blue text-whitern">Map Reference Title</th>
												<th className="w-10 bg-blue text-whitern center">Map Date</th>
												<th className="w-10 bg-blue text-whitern center">Clone Date</th>
												<th className="w-10 bg-blue text-whitern">Action</th>
											</tr>
										</thead>
										<tbody>
											{
												chrom.diseaseCloneList.map(disease => {
													return (
														<tr key={disease.sortNo} id={disease.diseaseId}>
															<td>
																<a href={"/portal/disease/edit?id=" + disease.diseaseId} title="See Disease" target="_blank"> <i className="bi bi-list"></i> </a>
																<span>{disease.sortNo} </span>
															</td>
															<td >
																<span>{
																	!!(disease.title) ?
																		<span dangerouslySetInnerHTML={{ __html: disease.title }} />
																		: ""
																}</span>
															</td>
															<td className="center">
																<span >{disease.mapPeriod}</span>
															</td>
															<td className="center">
																<span >{disease.clonePeriod}</span>
															</td>
															<td className="center">
																<a href={"/portal/mapclonedates/edit?id=" + disease.diseaseId} title="Edit"> <i className="bi bi-pencil-square"></i> </a> &nbsp;
																{/*<a onClick={() => this.ConfirmDelete(true, "Are you sure you want to delete " + disease.accessionDisease + "?", disease.accessionDisease)}><i className="bi bi-trash" title="Delete" ></i> </a>*/}
															</td>
														</tr>
													);
												})
											}
										</tbody>
									</table>
								</TabPanel>
							);
						})
					}
				</Tabs>
			</div>
		);
	}

	render() {
		let contents = this.state.loading
			? <div className="loader section-center"></div>
			: MapCloneDates.renderCloneDates(this.state.cloneDates, this.state.chromNo)
		
		return (
			<div className="form-container">
				<Helmet>
					<title>RetNet Portal: Map & Clone Dates </title>
				</Helmet>
			  <FormHeader />
			  <FormNav/>
				<ModalYesNo title="Delete Record" parentCallback={this.callbackYesDelete} />
				<Modal title="Delete Map Clone Date"
					okurl="/portal/mapclonedates"
					failurl="/portal/mapclonedates" />

				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					
					<h3 className="pad-10p">Map and Clone Dates</h3> 
					
					<hr />
					
					
					<div>
					
						{contents}

					 </div>
			  </div>
		  </div>
		);
		
	}

	async populateCloneDates() {
		const response = await fetch('/api/mapclonedates/getbychrom', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ cloneDates: data, loading: false });
		}
		else {
			alert(data.message);
		}
	}

}

