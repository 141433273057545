import React, { Component, useRef, Button } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { ModalYesNo } from "./components/ModalYesNo";
import { Modal } from "./components/Modal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import  Dropdown  from 'react-bootstrap/Dropdown';
import { getElementById } from '../../node_modules/domutils/lib/legacy';

export class PortalDisease extends Component {
	static displayName = PortalDisease.name;
	constructor(props) {
		super(props);
		this.state = {
			diseasesList: [],
			loading: true,
			chromNo: "chrom1",
			isDelete: false
		};
		//this.handleChange = this.handleChange.bind(this);
	}

	 async callbackYesFunction(diseaseData, callbackendpoint){

		 const submitendpoint = "api/diseases/" + callbackendpoint

		const response = await fetch(submitendpoint, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(diseaseData),
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();
		ModalYesNo.modalDisplay(false);
		Modal.modalDisplay(response.ok, data.message, "portal/disease");
	}

	delete = (val) => {
		this.setState({
			isDelete: val
		})
	}

	formMode = (val) => {
		this.setState({
			isInsert: val
		})
	}

	componentDidMount() {
		this.populateDiseasesData();
	}

	componentDidUpdate() {


	}

	static renderOmimLinks(strMcKusick) {
		const omims = strMcKusick.split(",");
		const omimlinks = omims.map((omim, index) => (
			<a key={index} href={"http://www.omim.org/entry/" + omim.replace(/ /g, '')} target="_blank" className="tableLinkList">{omim }</a>
		));
		return omimlinks;
	}
	static renderReferences(reference) {
		const references = reference.split(";");
		const referenceLinks = references.map((ref, index) => (
			<a className="tableLinkList" key={index} href={"./references#" + ref.replace(/ /g, '')}>{ref}</a>
		));
		return referenceLinks;
	}

	handleChange() {
		
		this.setState({ chromNo: 2 });
	};

	static chromosomeSelection(diseases, chromNo) {
		
		return (
			<div className="btn-group">
				<button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
					Choose Chromosome
				</button >
				<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					{
						diseases.map(p => {
							return (
								<button className="dropdown-item" >{p.chromosome}</button>
							)
						})
					}
				</div>
			</div>
		);
	}

	static ConfirmDelete(show, question, id, endpoint, color) {
		ModalYesNo.modalDisplay(show, question, id, endpoint, color);
	}


	static selectChromosome(id) {
		const li = document.getElementById("tab:"+id);
	}


	static renderDiseasesTable(diseases, chromNo) {
		//const curChrom = diseases.filter(item => item.chromTag === chromNo)
		return (
			<div>
				<Tabs>
					{
						<TabList>
							{
								diseases.map(curChrom => {
									return (
										<Tab key={curChrom.chromTag} id={ curChrom.chromTag}>{curChrom.chrom}</Tab>
									);
								})
							}
						</TabList>
					}
					{ 
					diseases.map(curChrom => { 
						return (
							<TabPanel key={curChrom.chromTag}>
								<h4 className="pad-5p">
									{ curChrom.chromosome}
								</h4>
								<table className="table table-bordered" aria-labelledby="tableLabel">
									<thead>
										<tr align="center">
											<th className="bg-blue text-whitern pad-2p w-5p"></th>
											<th className="w-10 bg-blue text-whitern">Sort By</th>
											<th className="w-15 bg-blue text-whitern">Symbols;<br />OMIM Numbers</th>
											<th className="w-10 bg-blue text-whitern">Location</th>
											<th className="bg-blue text-whitern">Diseases;<br />Protein</th>
											<th className="w-10 bg-blue text-whitern">Action</th>
											
										</tr>
									</thead>
									<tbody>
										{
											curChrom.diseases.map(disease => {
												return (
													<tr key={disease.id} id={disease.id}>
														{
															(disease.status === 1) ?
																<td className="bg-yellow pad-2p" title="Draft"></td> :
																(disease.status === 2) ?
																	<td className="bg-green pad-2p" title="Published"></td> :
																	<td className="bg-red pad-2p" title="Archived"></td>
														}
														<td className="center" title={ disease.id}>{disease.sortNo}</td>
														
														<td >
															{
																(disease.symbol1 || disease.symbol2) ?
																	<span>
																		<span className="data-group">
																			{
																				(disease.symbol1) ? <span className="font-weight-bold data-subgroup">{disease.symbol1}</span> : ""
																			}
																			{
																				(disease.symbol2) ? <span className="data-subgroup">{disease.symbol2}</span> : ""
																			}
																		</span>
																		<br />
																	</span>
																	: ""
															}
															<span className="data-group">
																{
																	(disease.mcKusick) ?
																		PortalDisease.renderOmimLinks(disease.mcKusick)
																		: ""
																}
															</span>
														</td>
														<td>
															<span dangerouslySetInnerHTML={{ __html: disease.location }} />
														</td>
														<td>
															{
																(disease.disease1) ?
																	<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease1 }} />
																	: ""
															}
															{
																(disease.disease2) ?
																	<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease2 }} />
																	: ""
															}
															{
																(disease.protein) ?
																	<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.protein }} />
																	: ""
															}
															{
																(disease.locusLink) ?
																	<span> <a href={"http://www.ncbi.nlm.nih.gov/gene/" + disease.locusLink} target="_blank">[Gene]</a></span>
																	: ""
															}
															{
																(disease.clinGen) ?
																	<span> <a href={"https://search.clinicalgenome.org/kb/genes/HGNC:" + disease.clinGen} target="_blank">[ClinGen]</a></span>
																	: ""
															}
														</td>
														<td className="center">
															<a href={"/portal/disease/edit?id=" + disease.id} title="Edit"><i className="bi bi-pencil-square"></i> </a> &nbsp;
															{(disease.status !== 10) ?
																<a onClick={() => this.ConfirmDelete(true, "Are you sure you want to Archive disease with Sort By <strong>" + disease.sortNo + "</strong> in <strong>" + curChrom.chromosome + "</strong>?", disease.id, "delete", "orange")}><i className="bi bi-archive text-orange" title="Archive" ></i> </a>
																:
																<a onClick={() => this.ConfirmDelete(true, "Are you sure you want to Permanently Delete disease with Sort By <strong>" + disease.sortNo + "</strong> in <strong>" + curChrom.chromosome + "</strong>?", disease.id, "permanentlydelete", "red")}><i className="bi bi-trash text-red" title="Permanently Delete"></i> </a>
															}
														</td>
													</tr>
												);
											})
										}
									</tbody>
								</table>
							</TabPanel>
						); })
					}
					</Tabs>
			</div>
		);
	}

	render() {
		let contents = this.state.loading
			? <div className="loader section-center"></div>
			: PortalDisease.renderDiseasesTable(this.state.diseasesList, this.state.chromNo)
		
		return (
			<div className="form-container">
				<Helmet>
					<title>RetNet Portal: Diseases </title>
				</Helmet>

			  <FormHeader />
			  <FormNav/>
				<ModalYesNo title="Diseases"
					parentCallback={this.callbackYesFunction} />
				<Modal title="Diseases"
					okurl="/portal/disease"
					failurl="/portal/disease" />

				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					<h3 className="pad-10p">Diseases <a type="button" href="/portal/disease/new" title="Add New Record"> <i className="bi bi-plus-circle"></i></a ></h3> 
					<hr />
					<div>
						{contents}
					</div>
			  </div>
		  </div>
		);
	}

	async populateDiseasesData() {
		const response = await fetch('/api/diseases/getbychrom');
		const data = await response.json();
		this.setState({ diseasesList: data, loading: false });
	}

}

