import React, { Component, useRef } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet";


export class Disease extends Component {
  static displayName = Disease.name;
	constructor(props) {
		super(props);
		this.state = {
			chromosomes: [],
			loading: true,
			count: 0,
			cntLoading: true,
			referencesData: [],
			refLoading: true
		};
	}


	componentDidMount() {
		this.populateDiseasesData();
		this.populateCount();
	}

	componentDidUpdate() {
		document
			.getElementById(window.location.hash.replace('#', ''))
			?.scrollIntoView({ behavior: 'instant', block: 'start' });
	}

	static renderOmimLinks(strMcKusick) {
		const omims = strMcKusick.split(",");
		const omimlinks = omims.map((omim, index) => (
			<a key={index} href={"http://www.omim.org/entry/" + omim.replace(/ /g, '')} target="_blank" className="tableLinkList">{omim}</a>
		));
		return omimlinks;
	}
	static renderReferences(reference) {

		const referenceLinks = reference.map((ref, index) => (
			<a className="tableLinkList" key={index} href={"./references#" + ref.referenceId}>{ref.reference}</a>
		));
		return referenceLinks;
	}


	static renderCount(cnt) {

		return (
			<div>
				<p className="center">Total entries = { cnt }. If you know the symbol but not the chromosome, please use the <a href="symbols">Symbols</a> list to find a gene.</p>
				<hr/>
			</div>
		);
	}



	static renderTables(diseases) {

		return (
			diseases.map(chromosome => {
				return (
					<div key={chromosome.chromTag} id={chromosome.chromTag} name={chromosome.chromTag}>
						<div className="cetner table-header">
							<h4>
								{chromosome.chromosome}
							</h4>
						</div>
						<table className="table table-bordered" aria-labelledby="tableLabel">

							<thead>

								<tr className="center">
									<th className="w-15"><span> Symbols;<br />OMIM Numbers</span></th>
									<th className="w-10"><span>Location</span></th>
									<th className="w-20"><span>Diseases;<br />Protein</span></th>
									<th className="w-40"><span>How Identified;<br />Comments</span></th>
									<th className="w-15"><span>References<br /></span> </th>
								</tr>
							</thead>
							<tbody>
								{
									/*this.renderDiseaseDetails(chromosome.diseases)*/
									chromosome.diseases.map(disease => {
										return (
											<tr key={disease.sortby} id={disease.id}>
												<td id={ disease.accessionDisease ? disease.accessionDisease : ""}>
													{
														(disease.symbol1 || disease.symbol2) ?
															<span>
																<span className="data-group">
																	{
																		(disease.symbol1) ? <span className="font-weight-bold data-subgroup">{disease.symbol1}</span> : ""
																	}
																	{
																		(disease.symbol2) ? <span className="data-subgroup">{disease.symbol2}</span> : ""
																	}
																</span>
																<br />
															</span>
															: ""
													}

													<span className="data-group">
														{
															(disease.mcKusick) ?
																Disease.renderOmimLinks(disease.mcKusick)
																: ""
														}
													</span>
												</td>
												<td>
													<span dangerouslySetInnerHTML={{
														__html:
															disease.location
													}} />
												</td>
												<td>
													{
														(disease.disease1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease1 }} />
															: ""
													}
													{
														(disease.disease2) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease2 }} />
															: ""
													}
													{
														(disease.protein) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.protein }} />
															: ""
													}
													{
														(disease.locusLink) ?
															<span> <a href={"http://www.ncbi.nlm.nih.gov/gene/" + disease.locusLink} target="_blank">[Gene]</a></span>
															: ""
													}
													{
														(disease.clinGen) ?
															<span> <a href={"https://search.clinicalgenome.org/kb/genes/HGNC:" + disease.clinGen} target="_blank">[ClinGen]</a></span>
															: ""
													}
												</td>
												<td>
													{
														(disease.disease1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.mappingMethod }} />
															: ""
													}
													{
														(disease.comment1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment1 }} />
															: ""
													}
													{
														(disease.comment2) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment2 }} />
															: ""
													}
													{
														(disease.comment3) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment3 }} />
															: ""
													}
												</td>
												<td>
													<span>
														{
															(disease.reference) ?
																Disease.renderReferences(disease.references) :
																""
														}
													</span>
												</td>
											</tr>
										);
									})
								}

							</tbody>
						</table>
						<p className="center">[ <a href="./disease#top">Top of Page</a> | <a href="./">Home Page</a> ]</p>
					</div>
				)
			})
		);
	}

	static renderDiseasesTable(diseases, references, count) {
		
		return (
			<div>
				<h3 className="center">Listed by chromosome:</h3>
					<p className="word-break center">[
					{
						diseases.map((chromosome, i, row) => {
							if (i + 1 === row.length) {
								return (
									<span>
										<a className="NavItem" href={"disease#" + chromosome.chromTag}>{chromosome.chrom}</a>
									</span>
								);
							} else {
								return (
									<span>
										<a className="NavItem" href={"disease#" + chromosome.chromTag}>{chromosome.chrom}</a>|
									</span>
								);
							}
						})
					}]
				</p>
				{
					Disease.renderCount(count)
				}
			</div>
		);
	}


	render() {
		let contents = this.state.loading && this.state.refLoading && this.state.cntLoading
			? <div className="loader section-center"></div>
			: Disease.renderDiseasesTable(this.state.chromosomes, this.state.referencesData, this.state.count);


		let tables = this.state.loading 
			? <div className="loader section-center"></div>
			: Disease.renderTables(this.state.chromosomes);


	  return (
		<div>
			<div className="App">
				<Helmet>
					<title>RetNet: Diseases Table</title>
				</Helmet>
			</div>
			<NavMenu />

			<div>
				{contents}
				{tables}
			</div>
		</div>
		);
		
	}

	async populateDiseasesData() {
		const response = await fetch('/api/diseases/getpublishedbychrom', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {

			this.setState({ chromosomes: data, loading: false });
		}
		else {
			alert(data.message);
		}
	}


	async populateCount() {
		const response = await fetch('/api/diseases/getcount', {
			signal: AbortSignal.timeout(20000)
		});
		
		const data = await response.json();
		if (response.ok) {
			
			this.setState({ count: data, cntLoading: false });
		}
		else {
			alert(data.message);
		}
	}
}

