import React, { Component } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { FormHeader } from "./FormHeader";
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { Input } from "./components/Input";
import { Modal } from './components/Modal';


export class ChangePassword extends Component {
    static displayName = ChangePassword.name;

    async registerHandler(e) {
        e.preventDefault();
        const form_ = e.target, submitter = document.querySelector("input.login");

        const formData = new FormData(form_, submitter), dataToSend = {};
   
        for (const [key, value] of formData) {
            dataToSend[key] = value;
        }

        try {
            const response = await fetch("api/authentication/changepassword", {
                method: "POST",
                credentials: "include",
                body: JSON.stringify(dataToSend),
                headers: {
                    "content-type": "Application/json",
                    "Accept": "application/json"
                }
            });
            const data = await response.json();
            if (response.ok) {
                e.target.reset();
                Modal.modalDisplay(response.ok, data.message);
            }
            else if (data.message)
            {
                Modal.modalDisplay(false, data.message);
            }
            else {

                if (!data.succeeded) {
                    Modal.modalDisplay(false, data.errors[0].description);
                    //messageEl.innerHTML = data.errors[0].description;
                } else {
                    Modal.modalDisplay(false, "Something went wrong, please try again.");
                    //messageEl.innerHTML = "Something went wrong, please try again.";
                }
            }
        }
        catch (err) {
            window.location.href = "/portal";
        }
    }


    render() {
        return (

            <div className="form-container">
                <Helmet>
                    <title>RetNet Poral: Change Password</title>
                </Helmet>
                <FormHeader />
                <FormNav />

                <Modal title="RetNet Poral: Change Password"
                    okurl="/portal"
                    failurl="/portal" />

                <div className="form-content box-shadow bg-white-smoke border-gray border-round">
                    <h3 className="pad-10p">Change Password</h3>
                    <hr/>
                    <form action="#" className='register' onSubmit={this.registerHandler}>

                        <Input label="Current Password" name="password" type="password" cssclass="w-25" />
                        <Input label="New Password" name="newPassword" type="password" cssclass="w-25" />
                        <Input label="Confirm Password" name="confirmPassword" type="password" cssclass="w-25" />

                        <br />
                        <div className="pad-1 f-inline-end">
                            <input type="submit" className='btn btn-primary margin-5' value="Change" />
                            <a className='register btn btn-secondary margin-5' href="/portal/">Cancel</a>

                        </div>
                    </form>
                    <p>&nbsp;</p>
                    <br />
                </div>
            </div>
        );
    }

}