import React, { Component } from 'react';
import $ from 'jquery';
export class LookupModal extends Component {
	static displayName = LookupModal.name;


	static lumodalDisplay(isok, message, name) {

		const modal = document.getElementById("lu-modal" + name );


		if (isok) {
			modal.style.display = "block";
			modal.focus();
		}
		else {
			modal.style.display = "none";
			modal.focus();
		}

	}

	static luCancel(selectedList, fieldname) {
		var checked_boxes = $('.' + fieldname +':input[type=checkbox]:checked');
		checked_boxes.each(function () {

			var $this = $(this);

			var elements = selectedList.split(', ');


			elements.forEach((element) => {
				
				var $chk = $("[id='" + element + "']");
				
				$chk.prop("checked", true);
			});

			if (!selectedList.split(', ').includes($this.attr("id"))) {
				$this.prop("checked", false);
			}
		});

		this.selected("lbl-selected" + fieldname,fieldname);
	}


	static selected(modalSelectedLabel, fieldname) {

		this.handleSelect(modalSelectedLabel, fieldname);

		this.lumodalDisplay(false, "", fieldname);
	}



	static handleSelect(modalSelectedLabel, fieldName) {

		var checked_boxes = $('.' + fieldName +':input[type=checkbox]:checked'),
			selected = [];
		checked_boxes.each(function () {
			var $this = $(this);

			selected.push($this.attr("id"));
		});

		$("#" + modalSelectedLabel).val(selected.join(', '))
	}


	render() {
		var selection = this.props.selections;
		return (
			
				<span className={this.props.cssclass + " " + this.props.cssfloat}>
					<label className="form-label" htmlFor={this.props.name}>{this.props.label} {this.props.required === true ? <span className="text-red">*</span> : ""} </label>
					{
						<input className={"form-control f-left w-hasicon"}
							type={this.props.type}
							name={this.props.name}
							id={this.props.name}
							required={(this.props.required) ? this.props.required : false}
							readOnly={true}
							defaultValue={(this.props.value) ? this.props.value : null}
							onClick={() => LookupModal.lumodalDisplay(true, "", this.props.name)}/>
					}
				<i className="bi bi-search f-left p-1" onClick={() => LookupModal.lumodalDisplay(true, "", this.props.name)}></i>

				<div id={"lu-modal" + this.props.name} className="modal lu-modal">
					<div className="modal-content">
						<div id={"modal-header" + this.props.name} className="modal-header bg-blue">
							<h5 className="text-whitern">
								{ this.props.title }
							</h5>
						</div>
						<div className="pad-1 w-100">
							<label htmlFor={"lbl-selected" + this.props.name}>Selected Items: </label>
							<textarea id={"lbl-selected" + this.props.name} className="w-100 border-none" defaultValue={this.props.value} readOnly/>
						</div>
						<div className="modal-body margin-t-unset border-gray">

							<table className="table table-bordered" aria-labelledby="tableLabel">
								<thead>
									<tr align="center">
										<th className="bg-blue text-whitern"></th>
										<th className="bg-blue text-whitern">Code</th>
										<th className="bg-blue text-whitern">{this.props.descriptioncaption ? this.props.descriptioncaption : "Description"} </th>
									</tr>
								</thead>

								<tbody>
									{
										(selection.length > 0) ?
											selection.map(heads => {
												return (
													<tr key={ heads.code}>
														<td>
															{(this.props.value) ?
																(this.props.value.split(', ').includes(heads.code)) ? 
																	<input type="checkbox" className={this.props.name} defaultChecked={true} id={heads.code} onClick={() => LookupModal.handleSelect("lbl-selected" + this.props.name, this.props.name )} /> : 
																	<input type="checkbox" className={this.props.name} id={heads.code} onClick={() => LookupModal.handleSelect("lbl-selected" + this.props.name, this.props.name)} />	:
																<input type="checkbox" className={this.props.name} id={heads.code} onClick={() => LookupModal.handleSelect("lbl-selected" + this.props.name, this.props.name)} />
																}
														</td>
														<td>{heads.code}</td>
														<td><span dangerouslySetInnerHTML={{ __html: heads.description }} /></td>
													</tr>
												)
											})
											: <tr >&nbsp;</tr>
									}
								</tbody>
							</table>
						</div>
						<div className="modal-footer">
							<a href={this.props.failurl} className="btn btn-primary" onClick={() => LookupModal.selected(this.props.name, this.props.name)}>OK</a>
							<a href={this.props.failurl} className="btn btn-secondary" onClick={() => LookupModal.luCancel($("#" + this.props.name).val(),  this.props.name)}>Cancel</a>
						</div>
					</div>
				</div>
			</span>
		);
	}
}