import React, { Component, useRef } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet";


export class References extends Component {
	static displayName = References.name;
	constructor(props) {
		super(props);
		this.state = { chromosomes: [] , loading: true };
	}


	componentDidMount() {
		this.populateDiseasesData();
	}

	componentDidUpdate() {
		document
			.getElementById(window.location.hash.replace('#', ''))
			?.scrollIntoView({ behavior: 'instant', block: 'start' });
	}


	static renderTopandHome() {
		return (
			<p align="center">[ <a href="references#top">Top of Page</a> | <a href="/">Home Page</a> ]</p>
		)
	}

	static renderReferences(references) {

		return (


			<div>
				<h3 align="center">Listed alphabetically by author:</h3>



				<p align="center">[
					{
						references.map((letterGroup, i, row) => {
							if (i + 1 === row.length) {
								return (
									<span>
										<a className="NavItem data-navItem" href={"references#" + letterGroup.letterGroup}>{letterGroup.letterGroup}</a>
									</span>
								);
							} else {
								return (
									<span>
										<a className="NavItem data-navItem" href={"references#" + letterGroup.letterGroup}>{letterGroup.letterGroup}</a>|
									</span>
								);
							}
						})
					}]
				</p>
			

				{

					references.map(letterGroup => {
						return (
							<div key={letterGroup.letterGroup} id={letterGroup.letterGroup}>
								<hr />
								<h4 name={letterGroup.letterGroup}>
									{letterGroup.letterGroup}
								</h4>
								<ul>
									{
										letterGroup.referenceItems.map(reference => {

											return (
												<li key={reference.sortBy} id={reference.id}> <p>
													<span dangerouslySetInnerHTML={{ __html: reference.authors + ". " }} />
													<span dangerouslySetInnerHTML={{ __html: (reference.title) ? reference.title + ". " : "" }} />
													<span dangerouslySetInnerHTML={{ __html: (reference.issuePages) ? reference.issuePages : "" }} />
													{(reference.year) ? <span> ({reference.year}). </span> : ""}
													{
														(reference.medLine) ?
															<a href={"http://www.ncbi.nlm.nih.gov/entrez/query.fcgi?db=PubMed&amp;cmd=Retrieve&amp;list_uids=" + reference.medLine + "&amp;dopt=Abstract"} target="_blank">[PubMed]</a>
															: ""
													}
												</p>
												</li>
											);
										})
									}
								</ul>
								{
									References.renderTopandHome()
								}


							</div>
						)
					})
				}
				</div>
		);
	}

	render() {
		let contents = this.state.loading
			? <div className="loader section-center"></div>
			: References.renderReferences(this.state.chromosomes);
		
	  return (
		<div>
			<div className="App">
				<Helmet>
					<title>RetNet: References</title>
				</Helmet>
			</div>
			<NavMenu />

			<div bgcolor="#ffffff" text="#000000" link="#0000ff" vlink="#ff0000" alink="#ff0000">
				
				  
				{contents}

			</div>
		</div>
		);
		
	}

	async populateDiseasesData() {
		const response = await fetch('/api/references/getpublished', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ chromosomes: data, loading: false });
		}
		else {
			alert(data.message);
		}
		
		
	}
}

