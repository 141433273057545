/// <reference path="../portal/components/input.js" />
import React, { Component, useState } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet"; 
import { Input } from "../portal/components/Input"
import { TextArea } from '../portal/components/TextArea';
import { Modal } from '../portal/components/Modal';
import $ from 'jquery';

export class ContactUs extends Component {
	static displayName = ContactUs.name

	constructor(props) {
		super(props);
		ContactUs.submitHandler = ContactUs.submitHandler.bind(this);

	}



	//componentDidMount() {
	//	this.RenderCaptCha();
	//}


	static async submitHandler() {

		const form_ = document.getElementById("fm-contactus");

		const formData = new FormData(form_),
			disease = {};

		
		for (const [key, value] of formData) {

			if (value !== "")
				disease[key] = value;
		}
		var submitendpoint = "/api/Smtp";
		
		try {

			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(disease),
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);

		}
		catch (err) {
			Modal.modalDisplay(false, err);
		}
	} 

	captchaError() {
		Modal.modalDisplay(true, "Error");
	}

	//RenderCaptCha() {
	//	var container = document.querySelector("#-my-captcha-container");

	//	AwsWafCaptcha.renderCaptCha(container, {
	//		apiKey: "iGQtV7OqCW7NjuVaF3MwOOR3KX9445BFA6LS/I2Z7JUnBCYr8sO1Zzb17w1GVktu9087t6S5JUFy+wH/fZaQwAoPcQwhNUTjiPcPB0ZxBEKkAbsSQeafuqgI5odnlA6N9a1oC4vt/ofYocB/zyT8tqSVGik+IEmmQrRmC/uf8U5vGDUeZ3gSQHqLVE8Ggz6xxydB9L15K6+NSmXdbfGdl6PVbqjI/ectTJkL4DkrtuQ0ZrA8tGslQV/Zn8520v3XhaI/Ry0ORirQGT0XxO3mXr1MTy2gpPXnvE89hhap2itiPM8rjGv2FWo5hmQSEXS1QJjvGrrIvygDu+eKeokSxYjyYV2dwu8TGb6cP0dUIXn5Qgn0xSogOQWLtJttlAKXnmhifg8SmYqX7q8pj3Bnsdla9qE1L8M5lXXsjjRRyJEnHEku0GG84V7G9PpCsvTCvlIOTm6YYgR9QG8R6wY6WGlabB8NPZZi8Jnq79/PoHK33SCzYG2j0Wekcyd4bZj6EMZyHojzTwkBnazB2W6zsI6CCs9KtKS3kGCZuoPgcHqwUOvlMmZEiRMbb8byMk4eXDzXw/kwOj9L2QxgdKWQOmd7pfaT6WxDnZ9x6bYN/TNjx4j3uZ82OoDb8fjo01EIoB4au4DwEZfbbKN1/wrjjQJv6DQN73Z78njZCqFIeoA=_0_1",
	//		onSuccess: this.submitHandler(),
	//		onError: this.captchaError(),
	//		dynamicWidth: false,
	//		skipTitle: true,
	//		disableLanguageSelector: true
	//	});
	//}

	static RenderForm() {
		return (
			<div>
				<div className="App">
					<Helmet>
						<title>RetNet: Contact Us</title>
					</Helmet>
				</div>
				<NavMenu />
				<Modal title="Talk to us" okurl="/" failurl="/contactus" />
				<div class="section-center pad-1 w-80 bg-white-smoke border-round">

					<div class="pad-2">
						<form id="fm-contactus">
							<h3 className="text-bluern ">Let us know your thoughts.</h3>
							<br />
							<Input label="First Name" name="firstname" type="Text" cssclass="pad-5p" />
							<Input label="Last Name" name="lastname" type="Text" cssclass="pad-5p" />
							<Input label="Email Address" name="emailAddress" type="Text" cssclass="pad-5p" />
							<Input label="Subject" name="subject" type="Text" cssclass="pad-5p" />
							<TextArea label="What would you like to discuss?" name="message" type="Text" cssclass="pad-5p" />

						</form>
						<a className="btn btn-primary margin-5" id="form-submit" onClick={e => { e.preventDefault(); this.submitHandler() }}>Submit</a>
					</div>
				</div>

			</div>
		);
	}


	render() {
		var content = ContactUs.RenderForm();

		return (
			<div className="content-w-m">
				{/*<Helmet>*/}
				{/*	<script type="text/javascript" src="https://2e54b70402ca.us-east-1.captcha-sdk.awswaf.com/2e54b70402ca/jsapi.js" defer></script>*/}
				{/*</Helmet>*/}
				{
					content
				}
			</div>
		);
	}
}
