import React, { Component } from 'react';
import $ from 'jquery'

export class ModalYesNo extends Component {
	static displayName = ModalYesNo.name;

	///param2 can be used for anything depending on the requirement of the calling component
	static modalDisplay(showme, message, id, param2, color) {
				
		const modal = document.getElementById("modalyn"),
			modalBody = document.getElementById("modalyn-Body"),
			modalHeader = document.getElementById("modalyn-header"),
			modalid = document.getElementById("data-id"),
			docparam2 = document.getElementById("data-param2");

		if (id) {
			modalid.innerHTML = id;
			
		}
		if (param2) {
			docparam2.innerHTML = param2;
		}


		if (color) {
			$("#modalyn-header").removeClass(function (index, className) {
				return (className.match(/(^|\s)bg-\S+/g) || []).join(' ');
			});

			$("#icon-question").removeClass(function (index, className) {
				return (className.match(/(^|\s)text-\S+/g) || []).join(' ');
			});


			$("#modalyn-header").addClass('bg-' + color);
			$("#icon-question").addClass('text-' + color);
		}



		if (showme) {
			modal.classList.add("display-block");
			modal.classList.remove("display-none");
			modal.focus();
		}
		else {
			modal.classList.add("display-none");
			modal.classList.remove("display-block");
			modal.focus();
		}

		if (message) {
			modalBody.innerHTML = message;
		}

	}

	handleYes = () => {
		const modalid = document.getElementById("data-id"),
			param2 = document.getElementById("data-param2");
		this.props.parentCallback(modalid.innerHTML, param2.innerHTML);
	}

	render() {
		return (
			<div id="modalyn" className="modal display-none">
				<div className="modal-content">
					<div id="modalyn-header" className="modal-header bg-orange">
						<h5 className="text-whitern">
							{ this.props.title }
						</h5>
					</div>
					<div className="modal-body">
						<i id="icon-question" className="bi bi-question-circle f-left text-orange display-block"></i>
						<p id="modalyn-Body"></p>
						<span id="data-id" className="display-none"></span>
						<span id="data-param2" className="display-none"></span>
					</div>
					<div className="modal-footer">
						<button id="mod-yes" href={this.props.failurl} className="btn btn-primary" onClick={() => this.handleYes()}>Yes</button>
						<button id="mod-no" href={this.props.failurl} className="btn btn-secondary" onClick={() => ModalYesNo.modalDisplay(false, "")}>No</button>
					</div>
				</div>
			</div>
		);
	}
}