import React, { Component, useRef } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet";


export class WhatsNew extends Component {
	static displayName = WhatsNew.name;
	constructor(props) {
		super(props);
		this.state = { chromosomes: [], loading: true, latestdates: [], datesloading: true, latestdate: "", dateloading: true };
	}


	componentDidMount() {
		this.populateDiseasesData();
		//this.populatestDates();
		this.populatestDate();
	}

	componentDidUpdate() {
		document
			.getElementById(window.location.hash.replace('#', ''))
			?.scrollIntoView({ behavior: 'instant', block: 'start' });
	}

	static renderOmimLinks(strMcKusick) {
		const omims = strMcKusick.split(",");
		const omimlinks = omims.map((omim, index) => (
			<a key={index} href={"http://www.omim.org/entry/" + omim.replace(/ /g, '')} target="_blank" className="tableLinkList">{omim }</a>
		));
		return omimlinks;
	}
	static renderReferences(reference) {

		const referenceLinks = reference.map((ref, index) => (
			<a className="tableLinkList" key={index} href={"./references#" + ref.referenceId}>{ref.reference}</a>
		));
		return referenceLinks;
	}

	static renderDiseasesTable(diseases, date) {
		if (diseases.length > 0) {

			return (
				<div>

					<h3 align="center">Listed in Chromosomal Order.</h3>
					{date}
					<p align="center">
						<font size="-1">Total entries = {diseases.length}.</font>
					</p>


					<div key={diseases.chromTag} id={diseases.chromTag}>

						<table className="table table-bordered" aria-labelledby="tableLabel">

							<thead>
								<tr>
									<td colspan="5" className="tableHeader" >
										<div align="cetner">
											<h4>
												New and Updated Retinal Disease Genes and Loci
											</h4>
										</div>
									</td>
								</tr>
								<tr align="center">
									<th nowrap="NOWRAP" width="12%">Symbols;<br />OMIM Numbers</th>
									<th width="12%">Location</th>
									<th width="28%">Diseases;<br />Protein</th>
									<th width="33%">How Identified;<br />Comments</th>
									<th width="15%">References<br /> </th>
								</tr>
							</thead>
							<tbody>
								{
									/*this.renderDiseaseDetails(chromosome.diseases)*/
									diseases.map(disease => {
										return (
											<tr key={disease.sortby} id={disease.accessionDisease}>
												<td >
													{
														(disease.symbol1 || disease.symbol1) ?
															<span>
																<span className="data-group">
																	{
																		(disease.symbol1) ? <span className="font-weight-bold data-subgroup">{disease.symbol1}</span> : ""
																	}
																	{
																		(disease.symbol2) ? <span className="data-subgroup">{disease.symbol2}</span> : ""
																	}
																</span>
																<br />
															</span>
															: ""
													}

													<span className="data-group">
														{
															(disease.mcKusick) ?
																WhatsNew.renderOmimLinks(disease.mcKusick)
																: ""
														}
													</span>
												</td>
												<td>
													<span dangerouslySetInnerHTML={{
														__html:
															disease.location
													}} />
												</td>
												<td>
													{
														(disease.disease1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease1 }} />
															: ""
													}
													{
														(disease.disease2) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.disease2 }} />
															: ""
													}
													{
														(disease.protein) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.protein }} />
															: ""
													}
													{
														(disease.locusLink) ?
															<span> <a href={"http://www.ncbi.nlm.nih.gov/gene/" + disease.locusLink} target="_blank">[Gene]</a></span>
															: ""
													}
													{
														(disease.clinGen) ?
															<span> <a href={"https://search.clinicalgenome.org/kb/genes/HGNC:" + disease.clinGen} target="_blank">[ClinGen]</a></span>
															: ""
													}
												</td>
												<td>
													{
														(disease.disease1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.mappingMethod }} />
															: ""
													}
													{
														(disease.comment1) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment1 }} />
															: ""
													}
													{
														(disease.comment2) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment2 }} />
															: ""
													}
													{
														(disease.comment3) ?
															<span className="data-group" dangerouslySetInnerHTML={{ __html: disease.comment3 }} />
															: ""
													}
												</td>
												<td>
													<span>
														{
															(disease.reference) ?
																WhatsNew.renderReferences(disease.references) :
																""
														}
													</span>
												</td>
											</tr>
										);
									})
								}
							</tbody>
						</table>
						<p align="center">[ <a href="./whatsnew#top">Top of Page</a> | <a href="./">Home Page</a> ]</p>
					</div>
				</div>
			);
		}
		else {
			return (
				<div className="center">
					<h3>No new Records found.</h3>
				</div>
			);
		}
	}

	static renderLatestDates(dates) {
		if (dates.length > 0) {
			return (
				<div className="element-center">
					<h3 className="center border-underline-lgray" >Record Creation and Modification Dates</h3>
					
					<table className="element-center">
					{
						dates.map(date => {
							return (
								<tr>
									<td className="w-250p">{date[0]}</td>
									<td><strong>{date[1]}</strong></td>
								</tr>
							)
						})
					}
					</table>
					<br/>
					<p align="center">[ <a href="./whatsnew#top">Top of Page</a> | <a href="./">Home Page</a> ]</p>
				</div>
			);
		}
	}


	static renderLatestDate(date) {
		if (date.length > 0) {
			return (
				<div>
					<p align="center">{date[0]}: <strong>{date[1]}</strong></p>
				</div>
			);
		}
	}

	render() {

		//let datecontents = this.state.datesloading
		//	? <div className="loader section-center"></div>
		//	: WhatsNew.renderLatestDates(this.state.latestdates);

		let datecontent = this.state.dateloading
			? <div className="loader section-center"></div>
			: WhatsNew.renderLatestDate(this.state.latestdate);

		let contents = this.state.loading & this.state.dateloading
			? <div className="loader section-center"></div>
			: WhatsNew.renderDiseasesTable(this.state.chromosomes);
		
	  return (
		<div>
			<div className="App">
				<Helmet>
					  <title>RetNet: What's New</title>
				</Helmet>
			</div>
			<NavMenu />

			  <div bgcolor="#ffffff" text="#000000" link="#0000ff" vlink="#ff0000" alink="#ff0000">	
				{datecontent}
				
				  {contents}
				  <br />
				{/*{datecontents}*/}
			</div>
		</div>
		);
	}

	async populateDiseasesData() {
		const response = await fetch('/api/diseases/getnew', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ chromosomes: data, loading: false });
		}
		else {
			alert(data.message);
		}
	}

	async populatestDates() {
		const response = await fetch('/api/diseases/getlatestdates', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ latestdates: data, datesloading: false });
		}
		else {
			alert(data.message);
		}
	}

	async populatestDate() {
		const response = await fetch('/api/diseases/getlatestdate', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ latestdate: data, dateloading: false });
		}
		else {
			alert(data.message);
		}
	}

}

