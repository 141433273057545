import React, { Component } from 'react';
import $ from 'jquery';


export class DropDown extends Component {
	static displayName = DropDown.name;


    componentDidUpdate() {
        var defaultVal = this.props.value ? this.props.value : 0;
        let element = $('#' + this.props.name);
        $(element).val(defaultVal);
    }


    componentDidMount() {
        var defaultVal = this.props.value ? this.props.value : 0;
        let element = $('#' + this.props.name);
        $(element).val(defaultVal);
    }

    render() {
        var items = [];

        if (this.props.options) {
            items = this.props.options;
        }
        else { 

            if (this.props.isYears === true) {
                items = DropDown.years();
            }
            else if (this.props.isMonths === true) {
                items = DropDown.months();
            }
            else if (this.props.isChromosomes === true) {
                items = DropDown.chromosomes();
            }
            else if (this.props.isAlphabet === true) {
                items = DropDown.alphabet();    
            }

        }

       
        if (items.length) {

            return (
                <span className={this.props.cssclass}>
                    {
                        (this.props.label) ?
                            <label className="form-label" htmlFor={this.props.name}>{this.props.label} {this.props.required === true ? <span className="text-red">*</span> : ""}</label> : ""
                    }
                    <select name={this.props.name} id={this.props.name} className={"form-control w-100"}>
                        <option value="0" >{this.props.caption}</option>
                        {
                            items.map(options => {

                                return (
                                    <option key={options.key} value={options.key}>{options.value}</option>
                                )

                            })
                        }
                    </select>
                </span>
            );
        }
    }



    static months() {
        var arr = [
            { value: "January", key: 1 },
            { value: "February", key: 2 },
            { value: "March", key: 3 },
            { value: "April", key: 4 },
            { value: "May", key: 5 },
            { value: "June", key: 6 },
            { value: "July", key: 7 },
            { value: "August", key: 8 },
            { value: "September", key: 9 },
            { value: "October", key: 10 },
            { value: "November", key: 11 },
            { value: "December", key: 12 }
        ];

        return arr;
    }

    static chromosomes() {
        var arr = [
            { value: "Chomosome 1", key: 1 },
            { value: "Chomosome 2", key: 2 },
            { value: "Chomosome 3", key: 3 },
            { value: "Chomosome 4", key: 4 },
            { value: "Chomosome 5", key: 5 },
            { value: "Chomosome 6", key: 6 },
            { value: "Chomosome 7", key: 7 },
            { value: "Chomosome 8", key: 8 },
            { value: "Chomosome 9", key: 9 },
            { value: "Chomosome 10", key: 10 },
            { value: "Chomosome 11", key: 11 },
            { value: "Chomosome 12", key: 12 },
            { value: "Chomosome 13", key: 13 },
            { value: "Chomosome 14", key: 14 },
            { value: "Chomosome 15", key: 15 },
            { value: "Chomosome 16", key: 16 },
            { value: "Chomosome 17", key: 17 },
            { value: "Chomosome 18", key: 18 },
            { value: "Chomosome 19", key: 19 },
            { value: "Chomosome 20", key: 20 },
            { value: "Chomosome 21", key: 21 },
            { value: "Chomosome 22", key: 22 },
            { value: "X Chromosome", key: 23 },
            { value: "Y Chromosome", key: 24 },
            { value: "Mitochondrion", key: 25 }
        ];

        return arr;
    }



    static years() {
        var arr = [
            { value: new Date().getFullYear(), key: new Date().getFullYear() },
        ];

        for (var i = new Date().getFullYear() -1; i >= 1961; i--) {
            arr.push({ value: i, key: i });
        }

        return arr;
    }

    static alphabet() {
        var arr = [
            { value: "A", key: 'A' },
            { value: "B", key: 'B' },
            { value: "C", key: 'C' },
            { value: "D", key: 'D' },
            { value: "E", key: 'E' },
            { value: "F", key: 'F' },
            { value: "G", key: 'G' },
            { value: "H", key: 'H' },
            { value: "I", key: 'I' },
            { value: "J", key: 'J' },
            { value: "K", key: 'K' },
            { value: "L", key: 'L' },
            { value: "M", key: 'M' },
            { value: "N", key: 'N' },
            { value: "O", key: 'O' },
            { value: "P", key: 'P' },
            { value: "Q", key: 'Q' },
            { value: "R", key: 'R' },
            { value: "S", key: 'S' },
            { value: "T", key: 'T' },
            { value: "U", key: 'U' },
            { value: "V", key: 'V' },
            { value: "W", key: 'W' },
            { value: "X", key: 'X' },
            { value: "Y", key: 'Y' },
            { value: "Z", key: 'Z' }
        ];

        return arr;
    }

}