import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Modal } from './components/Modal';
export class Login extends Component {
	static displayName = Login.name;


    async loginHandler(e) {

        e.preventDefault();
 
        const form_ = e.target, submitter = document.querySelector("input.login");

        const formData = new FormData(form_, submitter), dataToSend = {};
        const messageEl = document.querySelector(".loginMessage");
        for (const [key, value] of formData) {
            dataToSend[key] = value;
        }

        if (dataToSend.Remember === "on") {
            dataToSend.Remember = true;
        }

        const response = await fetch("api/authentication/login", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(dataToSend),
            headers: {
                "content-type": "Application/json",
                "Accept": "application/json"
            }
        });
        const data = await response.json();

        if (response.ok) {
            sessionStorage.setItem("user", dataToSend.username);
            document.location = "/portal";
        }

        else {
            Modal.modalDisplay(false, data.message);

        }
    }

    async handleForgotPassword(e) {
        e.preventDefault();

        const form_ = e.target, submitter = document.querySelector("input.login");

        const formData = new FormData(form_, submitter), dataToSend = {};
        const messageEl = document.querySelector(".loginMessage");
        for (const [key, value] of formData) {
            dataToSend[key] = value;
        }

        if (dataToSend.Remember === "on") {
            dataToSend.Remember = true;
        }

        const response = await fetch("api/authentication/sendresetpassword", {
            method: "POST",
            credentials: "include",
            body: JSON.stringify(dataToSend),
            headers: {
                "content-type": "Application/json",
                "Accept": "application/json"
            }
        });
        const data = await response.json();

        if (response.ok) {
            sessionStorage.setItem("user", dataToSend.username);
            document.location = "/portal";
        }

        else {
            Modal.modalDisplay(false, data.message);

        }
    }


    render() {
        return (
            <div > 
                <Helmet>
                    <title>RetNet Poral: Login</title>
                </Helmet>

                <Modal title="Login" />

                <div className="login-head bg-blue">
                    <span >Sign In</span>
                </div>
				<div className="login-form">
                    <div className='form-holder'>
                        <p className='loginMessage text-danger'></p>
                        <form action="#" className='login' onSubmit={this.loginHandler}>
                            <label className="form-label" htmlFor="username">User Name or Email Address</label>
                            <input className="form-control" type="text" name='username' id='username' required />
                            <br/>
                            <label className="form-label"  htmlFor="password">Password</label>
                            <input className="form-control" type="password" name='Password' id='password' required />
                            <br />
                            <div className="d-grid gap-2">
                                <input type="submit" value="Sign in" className="btn btn-primary"></input>
                            </div>
                            <br />
                            <a href="/portal/forgotpassword">Forgot Password?</a>
                        </form>
                    </div>
				</div>
            </div>
        );
    }

}