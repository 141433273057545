import React, { Component } from 'react';
import { Navigate  } from 'react-router-dom'
import { Modal } from "./components/Modal";

export class FormNav extends Component {
	static displayName = FormNav.name;
	constructor(props) {
		super(props);
		this.state = {
			userInfo: false,
			loading: true
		};
	}

	componentDidMount() {
		//this.userInfo = sessionStorage.getItem("user");
		this.checkAuth();


	}

	showRegisterUser() {
		this.setState({
			showRegister: true
		});
	}


	async logoutHandler() {

		const response = await fetch("api/authentication/logout", {
			method: "POST",
			credentials: "include"
		});

		const data = await response.json();
		if (response.ok) {
			sessionStorage.removeItem("user");
			Modal.modalDisplay(response.ok, data.message, "/portal");
			//window.location.href = "/portal"
		} else {
			alert("could not logout: ", response);
		}
	};

	render() {

		if (this.state.loading == false) {
			if (this.userInfo == false)
				this.logoutHandler();
		}

		return (
			<div>
				<Modal title="RetNet Portal"
					okurl="/portal"
					failurl="/portal" />

				 <div className="formNav box-shadow bg-white-smoke border-gray border-round">

					<a href="/portal">
						<p>
							<i className="bi bi-house-gear"></i>
							<span className="navitem"> &nbsp;Portal</span>
						</p>
					</a>

					<a href="/portal/disease">
						<p>
							<i className="bi bi-table"></i>
							<span className="navitem"> &nbsp;Diseases</span>
						</p>
					</a>
					<a href="/portal/references/">
						<p><i className="bi bi-book"></i>
							<span className="navitem"> &nbsp;References</span>
						</p>
					</a>
					<a href="/portal/mapclonedates/">
						<p><i className="bi bi-calendar-event"></i>
							<span className="navitem"> &nbsp;Map & Clone Dates</span>
						</p>
					</a>
				  <hr />
					<a href="/portal/users/new" >
						<p>
							<i className="bi bi-person-plus"></i>
							<span className="navitem">  New User </span>
						</p>
					</a>
					<a href="/portal/users/changepassword" >
						<p>
							<i className="bi bi-key"></i>
							<span className="navitem">  Change Password</span>
						</p>
					</a>
					{/*<a href="/portal/users/resetpassword" >*/}
					{/*	<p>*/}
					{/*		<i className="bi bi-key-fill"></i>*/}
					{/*		<span className="navitem">  Reset User Password</span>*/}
					{/*	</p>*/}
					{/*</a>*/}
				  <a onClick={() => this.logoutHandler()} ><p><i className="bi bi-box-arrow-left"></i><span className="navitem">  Log Out</span></p> </a>
				</div>
		  </div>
		);
	}

	async checkAuth() {

		const response = await fetch('/api/authentication', {
			signal: AbortSignal.timeout(20000),
			credentials: "include",
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});

		const data = await response.json();
		if (response.ok) {
			this.setState({ userInfo: data, loading: false });
		}
		else {
			window.location.href = "/portal";
		}
	}

}