import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
	static displayName = NavMenu.name;

	constructor(props) {
		super(props);

		this.toggleNavbar = this.toggleNavbar.bind(this);
		this.state = {
			collapsed: true
		};
	}

	toggleNavbar() {
		this.setState({
			collapsed: !this.state.collapsed
		});
	}

	render() {
		return (
			<header>
				<div className="navigation">
					<div className="headers">

						<h2 className="center" >
							<a name="top" href="">
								<img src="/img/logo.gif" alt="RetNet:" className= "w-250p"/>
							</a>
							<br />
							{window.location.pathname === "/disease" ? "Genes and Mapped Loci Causing Retinal Diseases" : null}
							{window.location.pathname === "/summaries" ? "Summaries of Genes and Loci Causing Retinal Diseases" : null}
							{window.location.pathname === "/symbols" ? "Symbols of Genes and Loci Causing Retinal Diseases" : null}
							{window.location.pathname === "/references" ? "References" : null}
							{window.location.pathname === "/whatsnew" ? "What's New in Genes and Loci Causing Retinal Diseases" : null}
							{window.location.pathname === "/faq" ? "Frequently Asked Questions" : null}
						</h2>
						<p className="word-break center"> [
							<a className="NavItem" href="/">Home</a>|
							{window.location.pathname !== "/disease" ? <span><a className="NavItem" href="disease">Disease Genes and Loci</a>|</span> : null}
							{window.location.pathname !== "/summaries" ? <span><a className="NavItem" href="summaries">Summaries</a>|</span> : null}
							{window.location.pathname !== "/symbols" ? <span><a className="NavItem" href="symbols">Symbols</a>|</span> : null}
							{window.location.pathname !== "/references" ? <span><a className="NavItem" href="references">References</a>|</span> : null}
							{window.location.pathname !== "/faq" ? <span><a className="NavItem" href="faq">FAQ</a>|</span> : null}
							<span><a className="NavItem" href="/contactus">Contact Us</a>]</span>
						
						</p>
					</div>
				</div>
				<hr />
			</header>
		);
	}
}
