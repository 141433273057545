import React, { Component } from 'react';

export class Modal extends Component {
	static displayName = Modal.name;


	static modalDisplay(isok, message, okUrl) {
				
		const modal = document.getElementById("modal"),
			modalHeader = document.getElementById("modal-header"),
			modalBody = document.getElementById("modalBody"),
			modalsuccessIcon = document.getElementById("icon-success"),
			modalfailIcon = document.getElementById("icon-fail"),
			modoks = document.getElementById("mod-ok-success"),
			modokf = document.getElementById("mod-ok-fail");

		if (okUrl)
			modoks.href = okUrl;


		if (isok) {
			modalsuccessIcon.style.display = "block";
			modalfailIcon.style.display = "none";

			modalHeader.classList.add("bg-green");
			modalHeader.classList.remove("bg-red");

			modoks.style.display = "block";
			modokf.style.display = "none";

			modal.style.display = "block";
			modal.focus();
		}
		else {
			modalsuccessIcon.style.display = "none";
			modalfailIcon.style.display = "block";

			modalHeader.classList.remove("bg-green");
			modalHeader.classList.add("bg-red");

			modoks.style.display = "none";
			modokf.style.display = "block";

			modal.style.display = "block";
			modal.focus();
		}

		if (message) {
			modalBody.innerHTML = message;
		} else {

			modalBody.innerHTML = "Something went wrong, please try again";
		}
	}

	static modalClose() {
		const modal = document.getElementById("modal")
		modal.style.display = "none";
	}


	render() {
		return (
			<div id="modal" className="modal">
				<div className="modal-content">
					<div id="modal-header" className="modal-header">
						<h5 className="text-whitern">
							{ this.props.title }
						</h5>
					</div>
					<div className="modal-body">
						<i id="icon-success" className="bi bi-check2-circle f-left text-green"></i>
						<i id="icon-fail" className="bi bi-x-circle f-left text-red"></i>
						<p id="modalBody"></p>
					</div>
					<div className="modal-footer">
						<a id="mod-ok-success" href={this.props.okurl} className="btn btn-primary">OK</a>
						<button id="mod-ok-fail" href={this.props.failurl} className="btn btn-primary" onClick={() => Modal.modalClose(false, "")}>OK</button>
					</div>
				</div>
			</div>
		);
	}
}