import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet"; 

export class Symbols extends Component {
    static displayName = Symbols.name;


    constructor(props) {
        super(props);
        this.state = {
            symbols: [],
            loading: true
        };
    }
    componentDidMount() {
        this.populateSymbolsData();
    }

    static renderSymbols(symbols, grp) {

        return (
            <table className="tb-3 table table-bordered symbols">
                <thead>
                    <tr>
                        <th scope="col" align="cetner">SYMBOL</th>
                        <th scope="col" align="cetner">LOCATION</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        symbols.filter(item => item.grp === grp).map((sym, index) => (
                            <tr key={sym.id } >
                                <td width="13%" bgcolor="#ffffff" className="left">
                                    <a href={"/disease#" + sym.id}>{sym.symbol}</a>
                                </td>
                                <td width="19%" bgcolor="#ffffff" className="left">

                                    <span dangerouslySetInnerHTML={{
                                        __html:
                                            sym.location
                                    }} />

                                 </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        );
    }

    static renderTables(symbols) {
		return (

            <div>
                {
                    Symbols.renderSymbols(symbols, 1)
                }
                {
                    Symbols.renderSymbols(symbols, 2)
                }
                {
                    Symbols.renderSymbols(symbols, 3)
                }
            </div>
		);
    }

    render() {

        let contents = this.state.loading
            ? <div className="loader section-center"></div>
            : Symbols.renderTables(this.state.symbols);

        return (
            <div>
                <div className="App">
                    <Helmet>
                        <title>RetNet: Symbols</title>
                    </Helmet>
                </div>
                <NavMenu />
                <div>
                    <p>
                        <strong>Note on Symbols:</strong> Gene symbols are currently assigned by the <a href="https://www.genenames.org/" target="_blank">HUGO Gene Nomenclature Committee (HGNC)</a> while <a href="https://www.ncbi.nlm.nih.gov/omim/" target="_blank">OMIM</a> independently assigns phenotype symbols, i.e., disease-specific symbols, which may be identical to or overlap with existing or reserved HGNC symbols.
                        The HGNC no longer provide nomenclature for phenotypes (where the associated gene is unidentified); their existing phenotype symbols were withdrawn in 2019.
                    </p>
                    <hr/>
                </div>
                <br/>
                <div className="tableContainer">
                    <h4>Disease Gene and Locus Symbols in Alphabetical Order (Three Columns) </h4>
                    { contents }
                </div>
                <br/>
                <p align="center">[ <a href="/symbols#top">Top of Page</a> | <a href="./">Home Page</a> ]</p>
            </div>
        );
    }

    async populateSymbolsData() {
        const response = await fetch('/api/symbols', {
            signal: AbortSignal.timeout(20000)
        });
        const data = await response.json();
        if (response.ok) {
            this.setState({ symbols: data, loading: false });
        }
        else {
            alert(data.message);
        }
        
    }
}