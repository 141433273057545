import React, { Component } from 'react';

export class Input extends Component {
	static displayName = Input.name;

	render() {
		return (
			<span className={this.props.cssclass}>
				<label className="form-label" htmlFor={this.props.name}>{this.props.label} {this.props.required === true ? <span className="text-red">*</span> : ""} </label>

				{
						<input className={"form-control w-100"}
							type={this.props.type}
							name={this.props.name}
							id={this.props.name}
							required={(this.props.required) ? this.props.required : false}
							readOnly={this.props.readOnly}
						defaultValue={(this.props.value) ? this.props.value : null}/>
				}
			</span>
		);
	}
}