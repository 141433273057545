import React, { Component } from 'react';

export class TextArea extends Component {
	static displayName = TextArea.name;

	render() {
		return (
			<span className={this.props.cssclass + " " + this.props.cssfloat}>
				<label className="form-label" htmlFor={this.props.name}>{this.props.label} {this.props.required === true ? <span className="text-red">*</span> : ""}</label>
				{
					!!!(this.props.required === true) ?
						<textarea className={"form-control w-100 h-10 "} type={this.props.type} name={this.props.name} id={this.props.name} readOnly={this.props.readOnly} />
						:
						<textarea className={"form-control w-100 h-10 "} type={this.props.type} name={this.props.name} id={this.props.name} required readOnly={this.props.readOnly} />
				}
			</span>
		);
	}
}