import React, { Component } from 'react';
import { Helmet } from "react-helmet";

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (

		<div className="content-w-m">
			<div className="App">
				<Helmet>
					<title>RetNet - Retinal Information Network</title>
				</Helmet>
			</div>

		<h1 className="text-navyrn" align="center" >
			<img className="logo" src="/img/logo.gif" alt="RetNet - The Retinal Information Network" border="0" />
			<br/>
			Retinal Information Network
		</h1>
		<hr/>
		<br/>
			<div>
				<div className="content-center m-w-700p">
					<table className="table">
						<tr>
							<td align="LEFT" colspan="5">
							<h3>Table of Contents:</h3>
							</td>
						</tr>
						<tr className="align-text-top">
							<td className="pad-l-1 w-10">
								<strong>
									<a href="disease">Diseases:</a>
								</strong>
							</td>
							<td>Genes and Mapped Loci Causing Retinal Diseases</td>
						</tr>
						<tr className="align-text-top">
							<td className="pad-l-1">
								<strong >
									<a href="summaries">Summaries:</a>
								</strong>
							</td>
							<td>
								Summary Tables (
									<a href="summaries#a-genes">Genes and Loci</a>,&nbsp;
									<a href="summaries#b-diseases">Diseases</a>,&nbsp;
									<a href="summaries#c-complex">Complex Diseases</a> or&nbsp;
									<a href="summaries#d-graph">Graph</a>)
								
							</td>
						</tr>
						<tr className="align-text-top">
							<td className="pad-l-1">
								<strong>
									<a href="/symbols">Symbols:</a>
								</strong>
							</td>
								<td>List of Disease Symbols</td>
						</tr>
						<tr className="align-text-top">
							<td className="pad-l-1">
								<strong>
									<a href="/references">References:</a>
								</strong>
							</td>
							<td>References for Disease Tables</td>
						</tr>
						<tr className="align-text-top">
							<td nowrap="NOWRAP" className="pad-l-1 ">
								<strong >
									<a href="whatsnew">What's New:</a>
								</strong>
							</td>
							<td className="bg-yellow">New and Updated Disease Genes</td>
						</tr>

						<tr className="align-text-top">
							<td className="pad-l-1">
								<strong>
									<a href="faq">FAQ</a>
								</strong>
							</td>
							<td>
									<a href="faq#notes">Notes</a>,&nbsp;
									<a href="faq#abbreviations">Abbreviations</a>,&nbsp;
									<a href="faq#faq">Frequently Asked Questions</a>
								
							</td>
						</tr>
					</table>
				</div>
			</div>
		<br/>

			<hr />
		<div>
			
				<p className="pad-l-1 pad-r-1">
				<strong>RetNet</strong> provides tables of genes and loci causing inherited retinal diseases, such as retinitis pigmentosa, macular degeneration and Usher syndrome, and related information. This information is provided to the research community and other interested individuals for research purposes only. The information should not be used for medical or commercial purposes. Although we strive for accuracy and completeness, we cannot guarantee that all information is correct and complete. We welcome <a href="/contactus">comments and suggestions</a>!
				</p>
			<hr/>
				<p className="pad-l-1 pad-r-1">
				<strong>RetNet</strong>, the Retinal Information Network, is a service of the Foundation Fighting Blindness, developed by Dr. Stephen P. Daiger with ongoing curation by Dr. Lori S. Sullivan at the University of Texas-Houston Health Science Center. Original website design, Dr. Belinda Rossiter.
				</p>
			
			<hr/>
				
				<div className="pad-l-1 pad-r-1">		
					<span className="pad-r-1 section-logo"><a href="http://www.fightingblindness.org/" target="_blank"> <img className="w-100" src="/img/ffb_web_full_color.png" alt="Foundation Fighting Blindness" /></a></span>
					<span><p>The urgent mission of the <strong>Foundation Fighting Blindness</strong> is to drive the research that will provide preventions, treatments and cures for people affected by retinitis pigmentosa, macular degeneration, Usher syndrome and the entire spectrum of retinal degenerative diseases.</p>	</span>
				
			</div>
		</div>
	</div>
    );
  }
}
