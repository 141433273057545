import React, { Component, useRef, Button } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { Input } from "./components/Input";
import { TextArea } from "./components/TextArea";
import { Modal } from "./components/Modal";
import { DropDown } from "./components/DropDown";
import JoditEditor, { Jodit } from 'jodit-react';
import $ from 'jquery';
import { ModalYesNo } from './components/ModalYesNo';
export class ReferencesForm extends Component {
	static displayName = ReferencesForm.name;
	constructor(props) {
		super(props);
		this.state = {
			referencesitem: {},
			loading: true
		};
		ReferencesForm.submitHandler = ReferencesForm.submitHandler.bind(this);
		ReferencesForm.changeStatusHandler = ReferencesForm.changeStatusHandler.bind(this);
	}

	componentDidMount() {

		if (window.location.pathname === "/portal/references/edit") {
			this.populateReferencesData();
		}
		else {
			this.setState({ loading: false });
		}
	}

	static getrichtextvalue(id) {

		var editor = $('#' + id).find(".jodit-wysiwyg")[0];

		this.removeLastBr(editor);

		if (editor) {
			var editor_val = editor.innerHTML;
			editor_val.replace(/^\uFEFF/gm, "");
			editor_val.replace(/[^\x20-\x7E]/g, '')
			if (editor.innerHTML.trim().length == 0) {
				return ""
			}

			if (editor_val.includes('<html>') || editor_val.includes('<body>') || editor_val.includes('<script>')) {
				throw new Error("Content for " + id + " contains unwanted elements that may cause rendering issues. Please review your entry.")
			}

			return editor_val;
		}
	}

	static removeLastBr(editor) {
		var br = $(editor).find('br:last-child')[0];
		if (br) {
			var nextSib = br.nextSibling;

			if (!nextSib) {
				br.remove();
				this.removeLastBr(editor);
			}

		}
	}


	static displayData(references)
	{
		if (window.location.pathname === "/portal/references/edit") {

			for (var key in references) {
				this.setformValue(references, key);
			}
		}
	}

	static setformValue(references, field) {
		const inputfield = document.getElementById(field);
		if (inputfield !== null) {
			inputfield.value = references[field]
		}
	}

	static closeModal() {
		const modal = document.getElementById("modal");
		modal.style.display = "none";
	}

	static async submitHandler(status) {
		
		const form_ = document.getElementById("fm-disease");
			
		const formData = new FormData(form_), references = {};
		$('span[data-jodit-temp="true"]').remove();
		for (const [key, value] of formData) {

			if(value !== "")
			references[key] = value;
		}
		try {
			references.title = ReferencesForm.getrichtextvalue("rt-title");
			references.authors = ReferencesForm.getrichtextvalue("rt-authors");

			const submitendpoint = window.location.pathname === "/portal/references/new" ?
				"api/references/add" : "api/references/update";

			if (status) {
				references.status = status;
			}

			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(references),
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}


	static ConfirmChangeStatus(show, question, id, param2) {
		ModalYesNo.modalDisplay(show, question, id, param2);
	}

	static async changeStatusHandler(id, status) {
		const reference = {};

		reference.id = $("#id").val();
		reference.status = status;

		const submitendpoint = "api/references/changestatus";
		try {
			ModalYesNo.modalDisplay(false);
			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(reference),
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}

	static renderForm(reference) {

		const buttons = [
			"undo",
			"redo",
			"|",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			"|",
			"superscript",
			"subscript",
			"|",
			"|",
			"link"
		];


		const editorConfig = {
			enter: 'br',
			readonly: false,
			toolbar: true,
			spellcheck: true,
			language: "en",
			toolbarButtonSize: "medium",
			toolbarAdaptive: false,
			showCharsCounter: true,
			showWordsCounter: true,
			showXPathInStatusbar: false,
			askBeforePasteHTML: true,
			askBeforePasteFromWord: true,
			defaultActionOnPaste: "insert_only_text",
			buttons: buttons,
			cleanHTML: {
				fillEmptyParagraph: false
			},
			uploader: {
				insertImageAsBase64URI: false
			},
			pasteHTMLActionList: Jodit.atom([
				{ value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' }
			]),
			width: 800,
			height: 800,
			addNewLineOnDBLClick: false,
			nl2brInPlainText: false,
			addNewLine: false
		};

		var pageTitle = "",
			isNew = false,
			callbackurl = "";
		if (window.location.pathname.includes("new")) {
			pageTitle = "New Reference";
			isNew = true;
			callbackurl = "/portal/references/";
		}
		if (window.location.pathname.includes("edit")) {
			pageTitle = "Edit Reference";
			isNew = false;
			callbackurl = "/portal/references/edit?id=" + reference.id;
		}
		return (
			<div className="form-container">
				<Helmet>
					<title>RetNet Portal: {pageTitle} </title>
				</Helmet>
				<ModalYesNo title={pageTitle}
					parentCallback={this.changeStatusHandler} />

				<Modal title={pageTitle}
					okurl={ callbackurl  }
					failurl={ callbackurl }/>


				<FormHeader />
				<FormNav />

				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					<h3 className="position-absolute pad-10p">{pageTitle}</h3>

					<form id="fm-disease" action="#" className='entryform'>
						<div className="btn-group">
							<a href="/portal/references" className="pad-10p"><i className="bi bi-x-lg"></i></a>
						</div>
						&nbsp;
						{
							(!isNew) ?
								(reference.status == 1) ?
									<div className="statusIndicator bg-yellow"></div> :
									(reference.status == 2) ?
										<div className="statusIndicator bg-green"></div> :
										<div className="statusIndicator bg-red"></div>
								: <hr />
						}
							<Input label="Id" name="id" type="Text" cssclass="w-25 display-none grid-item" required={true} readOnly="readOnly" value={(pageTitle === "Edit Reference") ? reference.id : ""} />
						<div className="grid-container">
							<div className="grid-item fields-padding">
								<DropDown label="Alphabet Group" caption="--" name="letterGroup" isAlphabet={true} cssclass="w-25" required={true} value={(pageTitle === "Edit Reference") ? reference.letterGroup : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Reference" name="reference1" type="Text" cssclass="w-25" required={true} value={(pageTitle === "Edit Reference") ? reference.reference1 : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Issue Pages" name="issuePages" type="Text" cssclass="w-25" value={(pageTitle === "Edit Reference") ? reference.issuePages : ""} />
							</div>
							<div className="grid-item fields-padding">
								<DropDown label="Year" caption="--" name="year" isYears={true} cssclass="w-25" required={true} value={(pageTitle === "Edit Reference") ? reference.year : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Gene Name" name="geneName" type="Text" cssclass="w-50" value={(pageTitle === "Edit Reference") ? reference.geneName : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Journal" name="journal" type="Text" cssclass="w-50" value={(pageTitle === "Edit Reference") ? reference.journal : ""} />
							</div>

							<div className="grid-item fields-padding">
								<Input label="MedLine" name="medLine" type="Text" cssclass="w-25" required={true} value={(pageTitle === "Edit Reference") ? reference.medLine : ""} />
							</div>

						</div>

						<div className="w-100 h-10 pad-10p margin-b-10" id="rt-title">
							<label className="form-label">Title <span className="text-red">*</span></label>
							<JoditEditor config={editorConfig} value={reference.title} tabIndex={1} />
						</div>

						<div className="w-100 h-10 pad-10p margin-b-10" id="rt-authors">
							<label className="form-label">Authors <span className="text-red">*</span></label>
							<JoditEditor config={editorConfig} value={reference.authors} tabIndex={1} />
						</div>

						<div className="pad-1 f-inline-end">
							{
								(reference.status === 1 || reference.status === 2 || isNew) ?
									<a className={isNew ? "btn btn-primary margin-5" : "btn btn-primary"} onClick={() => this.submitHandler()}>Save</a> :
									<a className="btn btn-primary margin-5" onClick={e => { e.preventDefault(); this.submitHandler(1) }}>Save as Draft</a>
							}
							{
								(reference.status === 1) ?
									<a className="btn btn-primary margin-5" onClick={e => { e.preventDefault(); this.submitHandler(2) }}>Save and Publish</a> :
									""
							}
							{
								(reference.status === 2) ?
									<a className="btn btn-primary margin-5" onClick={e => {
											e.preventDefault();
											this.ConfirmChangeStatus(true, "Changes to this record will not be saved. <br/>Do you wish to continue?",
												reference.id, 1)
										}}>Unpublish</a> :
									""
							}
							<a className='btn-red register btn btn-secondary' href="/portal/references">Cancel</a>
						</div>

					</form>
					<p>&nbsp;</p>
					<br />
				</div>
			</div>
		);

	}



	render() {

		let contents = this.state.loading
			? ""
			: ReferencesForm.renderForm(this.state.referencesitem, this.state.loading)
		
		return (
			<div>
				{
					contents
				}
			</div>
		);
	}

	async populateReferencesData() {

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		try {

			const response = await fetch("api/references/getitem?id=" + urlParams.get('id'), {

				credentials: "include",
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();
			if (response.ok) 
			{
				this.setState({ referencesitem: data, loading: false });
			}
			else { alert(data.message); }
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}

}

