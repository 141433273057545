import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet"; 

export class Pagenotfound extends Component {
	static displayName = Pagenotfound.name;


	render() {
		return (
			<div >
				<h2 className="center" >
					
					<a href=""><img src="/img/logo.gif" alt="RetNet:" width="250" border="0" /></a>
					<hr/>
					<br/>
				</h2>
				<div className="App">
					<Helmet>
						<title>RetNet: Page not found</title>
					</Helmet>
				</div>
				<div className="center">
					<h2 className="font-weight-bold text-red"> <i className="bi bi-file-earmark-x"></i> 404 - Page not found</h2>

					<p>Oops! we cant find that page. Why not return to <span><a href="">Home</a></span> or check out our other pages: </p>

					
					<span>[ <a className="NavItem" href="disease">Disease Genes and Loci</a> | </span>
					<span><a className="NavItem" href="summaries">Summaries</a> | </span>
					<span><a className="NavItem" href="symbols">Symbols</a> | </span>
					<span><a className="NavItem" href="references">References</a> | </span>
					<span><a className="NavItem" href="faq">FAQ</a> ]</span>
				</div>
			</div>
		);
	}
}
